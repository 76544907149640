import { BUY_PAGE_DEFAULT_LIMIT } from "@/libs/constants";
import { Skeleton } from "./ui/skeleton";
import range from "lodash/range";
import { useMemo } from "react";
import { useRouter } from "next/router";

export function VehicleListingsCardSkeleton() {
  const router = useRouter();
  const isHomePage = useMemo(() => {
    return router.asPath === "/";
  }, []);
  if (isHomePage) {
    return (
      <div className="w-full lg:max-w-[1200px] px-2  mx-auto overflow-y-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 py-4">
        {range(0, 4).map((index) => (
          <VehicleCardSkeleton key={index} />
        ))}
      </div>
    );
  }
  return (
    <div className="w-full lg:max-w-[1140px] px-2 vs:px-[8%] lg:px-0 mx-auto overflow-y-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
      {range(0, BUY_PAGE_DEFAULT_LIMIT).map((index) => (
        <VehicleCardSkeleton key={index} />
      ))}
    </div>
  );
}

export function VehicleCardSkeleton() {
  return (
    <div className="w-full h-full bg-white rounded-lg shadow cursor-pointer flex flex-col gap-4">
      <Skeleton className="h-[242px] w-full rounded-b-none" />
      <div className="flex flex-col gap-1 px-5 pt-4">
        <Skeleton className="h-5 w-[213px] rounded-none" />
        <Skeleton className="h-4 w-[163px] rounded-none" />
        <Skeleton className="h-3 w-[124px] rounded-none" />
      </div>
      <div className="flex justify-between px-4 pb-4 items-center">
        <Skeleton className="h-5 w-[124px] rounded-none" />
        <Skeleton className="h-9 w-9 rounded-full" />
      </div>
    </div>
  );
}
