import { useCallback, useState } from "react";
import Image from "next/image";

interface Props {
  data: any;
}

const MAX_BIO_LENGTH = 350; // Set your desired maximum length here

const truncateBio = (bio: string, maxLength: number) => {
  if (bio.length <= maxLength) return bio;
  const truncated = bio.substring(0, maxLength);
  const lastPeriodIndex = truncated.lastIndexOf(".");
  if (lastPeriodIndex === -1) return `${truncated}...`; // No period found, just truncate
  return `${truncated.substring(0, lastPeriodIndex + 1)}...`;
};

export const MiniAuthorBio = ({ data }: Props) => {
  const { name, headshotUrl, slug, createdAt, updatedAt, AuthorBio } = data;
  const truncatedBio = truncateBio(AuthorBio, MAX_BIO_LENGTH);

  return (
    <div className="w-full flex flex-col sm:flex-row p-8 justify-center py-3 h-auto max-w-7xl mx-auto  pb-10">
      <div className="h-auto rounded-lg text-center sm:mr-4 mb-4 sm:mb-0 flex-shrink-0">
        <div className="px-11 pt-6 flex justify-center">
          <Image
            className="rounded-lg"
            src={headshotUrl}
            width={250}
            height={230}
            alt={"author image"}
          />
        </div>
      </div>

      <div className="flex flex-col justify-between h-auto flex-grow">
        <div className="py-4">
          <div className="text-[24px] font-700">{name}</div>
          <div className="text-[20px] font-500">Contributing Author</div>
        </div>
        <div className="text-[20px] font-500 mb-4 sm:mb-0 leading-10">
          {truncatedBio}
        </div>
        <div className="flex flex-col">
          <a
            className=" pt-4 text-[20px] font-500"
            href={slug}
            target="_blank"
            rel="noreferrer"
          >
            Read more
          </a>
          <span className=" left-0 bottom-[-2px] w-full h-[2px] bg-[#00B3DE] max-w-[95px] "></span>
        </div>
      </div>
    </div>
  );
};
