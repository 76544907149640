import { useRef } from "react";
import { sortArray } from "@/libs/constants";
import { useSearchValue } from "@/contexts/contextSearch";
import useOutsideClick from "@/hooks/useOutsideClick";

type Props = {
  isSortModal: Boolean;
  setIsSortModal: Function;
  setSort: Function;
  btnRef: any;
};

const SortModal = ({ isSortModal, setIsSortModal, setSort, btnRef }: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { setCurrent } = useSearchValue();
  const sort = (name: string) => {
    setIsSortModal(false);
    setCurrent(0);
    if (name == "Newest inventory") {
      setSort("Newest");
    } else {
      setSort(name);
    }
  };

  useOutsideClick(modalRef, isSortModal, setIsSortModal, btnRef);

  return (
    <div
      className="absolute top-[60px] right-0 w-[172px] py-6 bg-white text-base text-[#333] border shadow-2xl rounded z-30"
      ref={modalRef}
    >
      {sortArray.map((item, index) => {
        return (
          <div
            key={index}
            className="cursor-pointer px-4 py-1 hover:bg-[#e9ecef]"
            onClick={() => sort(item)}
          >
            {item}
          </div>
        );
      })}
    </div>
  );
};

export default SortModal;
