import { useState, useEffect, useRef } from "react";
import Image from "next/image";
import useApi from "@/hooks/useApi";
import { useSearchValue } from "@/contexts/contextSearch";
import CloseIcon from "@/components/Icon/CloseIcon";
import useOutsideClick from "@/hooks/useOutsideClick";

type Props = {
  makeData: Array<string>;
  setIsMakeModelModal: Function;
  isMakeModelModal: Boolean;
};

const MakeModelModal = ({
  makeData,
  setIsMakeModelModal,
  isMakeModelModal,
}: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { make, models, setMake, setModels, clearMakeModel, setCurrent } =
    useSearchValue();
  const initModel = make == "" ? false : true;
  const [searchValue, setSearchValue] = useState("");
  const [listData, setListData] = useState<Array<string>>(makeData);
  const [modelData, setModelData] = useState<Array<string>>([]);
  const [selectedModels, setSelectedModels] = useState<Array<string>>(models);
  const [isModel, setIsModel] = useState<Boolean>(initModel);
  const [selectedMake, setSelectedMake] = useState<string>(make);
  const { getModelDataByMake } = useApi();

  useEffect(() => {
    if (make != "") {
      getModelByMake(make);
    }
  }, []);

  const getModelByMake = async (make: string) => {
    const data = await getModelDataByMake(make);
    setIsModel(true);
    setSearchValue("");
    setSelectedMake(make);
    setModelData(data.results.models);
    setListData(data.results.models);
  };

  const backToMake = () => {
    setIsModel(false);
    setListData(makeData);
  };

  const searchMakes = (e: any) => {
    if (e.target.value == "") {
      setListData(makeData);
    } else {
      const searchKey = e.target.value;
      let searchedMakeList: any = [];
      makeData.map((item: string, index: number) => {
        if (item.toLowerCase().includes(searchKey.toLowerCase())) {
          searchedMakeList.push(item);
        }
      });
      setSelectedModels([]);
      setListData(searchedMakeList);
    }
    setSearchValue(e.target.value);
  };

  const searchModels = (e: any) => {
    if (e.target.value == "") {
      setListData(modelData);
    } else {
      const searchKey = e.target.value;
      let searchedMakeList: any = [];
      modelData.map((item: string, index: number) => {
        if (item.toLowerCase().includes(searchKey.toLowerCase())) {
          searchedMakeList.push(item);
        }
      });
      setListData(searchedMakeList);
    }
    setSearchValue(e.target.value);
  };

  const clickModel = (model: string) => {
    const models = selectedModels.slice();
    if (models.includes(model)) {
      const index = models.indexOf(model);
      if (index > -1) {
        models.splice(index, 1);
      }
    } else {
      models.push(model);
    }
    setSelectedModels(models);
  };

  const apply = () => {
    setIsMakeModelModal(false);
    setCurrent(0);
    setMake(selectedMake);
    setModels(selectedModels);
  };

  const clear = () => {
    setIsMakeModelModal(false);
    clearMakeModel();
  };

  useOutsideClick(modalRef, isMakeModelModal, setIsMakeModelModal);

  return (
    <div className="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-60 justify-center items-center overflow-x-hidden inset-0 z-50 outline-none focus:outline-none">
      <div
        className="w-full xs:w-[390px] mx-auto opacity-100 bg-white text-[#333] border-1 border-2-gray-400 shadow-md rounded-xl py-4 mt-40"
        ref={modalRef}
      >
        <div className="w-full flex justify-end px-6">
          <CloseIcon
            fill="#818181"
            className="cursor-pointer"
            onClick={() => {
              setIsMakeModelModal(false);
              document.body.style.overflowY = "scroll";
            }}
          />
        </div>
        <div className="w-full px-6 pb-8 space-y-4">
          <div className="text-left">
            {isModel && (
              <div
                className="text-xs font-medium text-[#063829] cursor-pointer"
                onClick={() => backToMake()}
              >
                &lt;&nbsp;Back to make
              </div>
            )}
            <label className="form-label inline-block text-base font-medium text-[#333] pb-4">
              {isModel ? selectedMake : "Make"}
            </label>
            {isModel ? (
              <input
                type="text"
                className="form-control block w-full px-3 py-1.5 text-sm font-medium text-[#333] bg-white bg-clip-padding border border-solid border-gray-300 rounded m-0 focus:border-[#a3b0ca] focus:text-gray-700 focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all"
                placeholder="Search models"
                value={searchValue}
                onChange={(e: any) => searchModels(e)}
              />
            ) : (
              <input
                type="text"
                className="form-control block w-full px-3 py-1.5 text-base font-medium text-[#333] bg-white bg-clip-padding border border-solid border-gray-300 rounded m-0 focus:border-[#a3b0ca] focus:text-gray-700 focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all"
                placeholder="Search makes"
                value={searchValue}
                onChange={(e: any) => searchMakes(e)}
              />
            )}
          </div>
          <div className="h-[130px] whitespace-nowrap overflow-auto scrollbar-hide text-left">
            {listData.map((item: string, index: number) => {
              return (
                <div key={index}>
                  {!isModel ? (
                    <div
                      className="flex justify-between cursor-pointer px-2"
                      key={index}
                      onClick={() => getModelByMake(item)}
                    >
                      <div className="text-sm font-medium text-[#333] py-2">
                        {item}
                      </div>
                      <Image
                        width={6}
                        height={12}
                        src="assets/right.svg"
                        alt="right"
                      />
                    </div>
                  ) : (
                    <div
                      className="flex items-center cursor-pointer px-2 space-x-2"
                      key={index}
                      onClick={() => clickModel(item)}
                    >
                      {selectedModels.includes(item) ? (
                        <Image
                          width={20}
                          height={20}
                          src="/assets/checkbox-checked.svg"
                          alt="checkbox-checked"
                        />
                      ) : (
                        <Image
                          width={20}
                          height={20}
                          src="/assets/checkbox-unchecked.svg"
                          alt="checkbox-unchecked"
                        />
                      )}
                      <div className="text-sm font-medium text-[#333] py-2">
                        {item}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <hr className="w-full" />
        <div className="flex justify-between px-4 pt-6">
          <button
            className="bg-[#f7f9fc] text-[#2c564a] text-sm font-medium py-2 px-3 hover:border-transparent rounded"
            onClick={() => clear()}
          >
            Clear
          </button>
          <button
            className="bg-[#00b3de] text-white text-sm font-bold py-2 px-3 rounded cursor-pointer"
            onClick={() => apply()}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default MakeModelModal;
