import { useState, useRef } from "react";
import { parseTitle, checkEmailIsValid } from "@/libs/utils";
import UniversalHTag from "@/libs/UniversalHTag";
import useApi from "@/hooks/useApi";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Snackbar from "@/components/alert/Snackbar";

type Props = {
  data: any;
};

const ContactForm = ({ data }: Props) => {
  const snackbarRef = useRef<HTMLDivElement>(null);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isClicked, setIsClicked] = useState<Boolean>(false);
  const [submissionMessage, setSubmissionMessage] = useState<string>("");
  const [isShow, setIsShow] = useState<boolean>(false);
  const [type, setType] = useState<string>("failure");

  const { executeRecaptcha } = useGoogleReCaptcha();
  const { submitContact } = useApi();
  const sendMessage = () => {
    try {
      setIsClicked(true);
      if (validateData()) {
        if (!executeRecaptcha) {
          setSubmissionMessage("Unable to send messages right now");
          setType("failure");
          setIsShow(true);
          return;
        }
        executeRecaptcha("LOGIN").then((token) => {
          submitContact({ name, email, message, captcha: token }).then(
            (response: any) => {
              setSubmissionMessage("Successfully sent message.");
              setType("success");
              setIsShow(true);
            }
          );
          setIsClicked(false);
          setMessage("");
          setEmail("");
          setName("");
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const validateData = (): boolean => {
    return name != "" && checkEmailIsValid(email) && message != "";
  };

  return (
    <div className="w-full">
      <div className="w-full bg-[#EFF0F6] py-8">
        <UniversalHTag
          tag={data.TitleStyle}
          classname={`text-center text-2xl ${parseTitle(data.TitleStyle)}`}
          content={data.Title}
        />
      </div>
      <div className="w-full py-12 text-[#828282]">
        <div className="w-full vs:max-w-[430px] mx-auto px-8">
          <div className="w-full space-y-2">
            <label className="text-xl">Your Name</label>
            <input
              type="text"
              className={`form-control block w-full px-4 py-3 text-base font-medium bg-white bg-clip-padding border border-solid ${
                isClicked && name == "" ? "border-red-700" : "border-[#9797aa]"
              } rounded-lg outline-none focus:text-gray-700 focus:bg-white focus:shadow-md focus:shadow-[#65d7f3] focus:ring-2 transition-all`}
              placeholder="First and last name"
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
            {isClicked && name == "" && (
              <div className="text-sm text-left text-[#ed0a0a] pt-2">
                Name is required.
              </div>
            )}
          </div>
          <div className="w-full space-y-2 pt-8">
            <label className="text-xl">Email Address</label>
            <input
              type="text"
              className={`form-control block w-full px-4 py-3 text-base font-medium bg-white bg-clip-padding border border-solid ${
                isClicked && (email == "" || !checkEmailIsValid(email))
                  ? "border-red-700"
                  : "border-[#9797aa]"
              } rounded-lg outline-none focus:text-gray-700 focus:bg-white focus:shadow-md focus:shadow-[#65d7f3] focus:ring-2 transition-all`}
              placeholder="What's your email?"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
            />
            {isClicked && email == "" && (
              <div className="text-sm text-left text-[#ed0a0a] pt-2">
                Email is required.
              </div>
            )}
          </div>
          <div className="w-full space-y-2 pt-8">
            <label className="text-xl">Message</label>
            <textarea
              rows={10}
              className={`form-control block w-full px-4 py-3 text-base font-medium bg-white bg-clip-padding border border-solid ${
                isClicked && message == ""
                  ? "border-red-700"
                  : "border-[#9797aa]"
              } rounded-lg outline-none focus:text-gray-700 focus:bg-white focus:shadow-md focus:shadow-[#65d7f3] focus:ring-2 transition-all`}
              placeholder="Write your message"
              value={message}
              onChange={(e: any) => setMessage(e.target.value)}
            />
          </div>
          <div className="flex justify-center py-12">
            <button
              className="bg-[#00b3de] text-base text-white font-bold py-2 px-3 border border-solid border-[#00b3de] focus:outline focus:outline-[#bec3cd] focus:outline-4 transition-all rounded-lg"
              onClick={() => sendMessage()}
            >
              Send message
            </button>
          </div>
        </div>
      </div>
      {isShow && (
        <Snackbar
          type={type}
          message={submissionMessage}
          setIsShow={setIsShow}
          snackbarRef={snackbarRef}
        />
      )}
    </div>
  );
};

export default ContactForm;
