import { useEffect, useMemo, useRef, useState } from "react";
import Link from "next/link";
import Geocode from "react-geocode";
import Filter from "@/components/buy/Filter";
import Pagination from "@/components/buy/Pagination";
import VehicleCard from "@/components/VehicleCard";
import NoMatch from "@/components/buy/NoMatch";
import useApi from "@/hooks/useApi";
import { initFilters } from "@/libs/constants";
import UniversalHTag from "@/libs/UniversalHTag";
import { parseTitle, checkHomePage } from "@/libs/utils";
import { useSearchValue } from "@/contexts/contextSearch";
import pick from "lodash/pick";
import {
  MIN_PRICE,
  MAX_PRICE,
  MIN_YEAR,
  MAX_YEAR,
  MIN_MILES,
  MAX_MILES,
  BUY_PAGE_DEFAULT_LIMIT,
} from "@/libs/constants";
import { googleGeocodeApiKey } from "@/libs/clientConfig";
import { verificationFilters } from "@/interfaces/User";
import { useRouter } from "next/router";
import { useRouteHistoryStore } from "@/stores/route-history.store";
import CTA from "./CTA";
import { VehicleListingsCardSkeleton } from "./VehicleCardSkeleton";

type Props = {
  key?: any;
  data: any;
  isNative?: boolean;
};

enum InitialDataState {
  idle = "idle",
  loading = "loading",
  loaded = "loaded",
}

const VehicleSearch = ({ data, isNative }: Props) => {
  const router = useRouter();
  const similarIsShow = data?.ShowSimilarListings;
  const { getPageData, getInitMakeData, getCarDetailsFilter } = useApi();
  const routeHistoryStore = useRouteHistoryStore();
  const {
    vehicleType,
    setVehicleType,
    searchKey,
    setSearchKey,
    make,
    setMake,
    models,
    setModels,
    bodyType,
    setBodyType,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    minYear,
    setMinYear,
    maxYear,
    setMaxYear,
    minMiles,
    setMinMiles,
    maxMiles,
    setMaxMiles,
    isVerified,
    setIsVerified,
    moreFiltersArr,
    setMoreFiltersArr,
    state,
    setState,
    location,
    setLocation,
    lat,
    setLat,
    lng,
    setLng,
    radius,
    setRadius,
    sort,
    setSort,
    current,
    setCurrent,
    setSelectedRadius,
    setSelectedPlace,
    setSelectedState,
    setSelectedLat,
    setSelectedLng,
    setIsOnlyState,
    syncedState,
    updateQuery,
  } = useSearchValue();
  const [isCleared, setIsCleared] = useState(false);
  const [initialDataState, setInitialDataState] = useState(
    InitialDataState.idle
  );

  const imageFormat = (data: any) => {
    if (Array.isArray(data)) {
      data.map((item: any) => {
        if (!item.uploadImages[0]) {
          return;
        }
        const image_url = item.uploadImages[0].images;
        //replace the image url fit for source url
        if (item.uploadImages[0].images.includes("vehicle-listing")) {
          item.uploadImages[0].images =
            "/images/" +
            image_url.substring(
              image_url.indexOf("vehicle-listing"),
              image_url.length
            );
        } else if (item.uploadImages[0].images.includes("jfif")) {
          item.uploadImages[0].images = item.uploadImages[0].images;
        } else {
          item.uploadImages[0].images =
            "/images/" +
            image_url.substring(
              image_url.indexOf("listings"),
              image_url.length
            );
        }
      });
    }
  };

  const rows = data.DisplayRows || 6;
  const limit =
    data?.MaxListings && data?.MaxListings > 0
      ? data.MaxListings
      : BUY_PAGE_DEFAULT_LIMIT;
  const pageSize = limit;

  const shouldUseStaticData = useMemo(() => {
    const isBuyPage =
      router.asPath === "/buy" || router.asPath.endsWith("/buy");
    return Boolean(
      isBuyPage && !router.asPath?.includes("?") && data?.initialCarData
    );
  }, []);

  const initPageData = () => {
    if (shouldUseStaticData) {
      return data.initialCarData.pageData;
    }
    return data?.vehicleListing ?? [];
  };
  const initSimilarData = () => {
    if (shouldUseStaticData) {
      return data.initialCarData.similarData;
    }
    return [];
  };
  const initTotal = () => {
    if (shouldUseStaticData) {
      return data.initialCarData.total;
    }
    return 0;
  };
  const initIsFetchingListings = () => {
    return !shouldUseStaticData;
  };
  const [isFetchingListings, setIsFetchingListings] = useState(
    initIsFetchingListings
  );
  const [pageData, setPageData] = useState<any>(initPageData);
  const [similarData, setSimilarData] = useState<any>(initSimilarData);
  const [total, setTotal] = useState<number>(initTotal);
  const [makeData, setMakeData] = useState<Array<string>>(data.makeData ?? []);
  const [moreFilterData] = useState<any>(data.moreFilterData ?? null);

  const searchParameters = useMemo(
    () => ({
      rows,
      limit,
      current,
      vehicleType,
      searchKey,
      make,
      models,
      bodyType,
      minPrice,
      maxPrice,
      minYear,
      maxYear,
      minMiles,
      maxMiles,
      isVerified,
      location,
      state,
      lat,
      lng,
      radius,
      sort,
      ...moreFiltersArr,
    }),
    [
      rows,
      limit,
      current,
      vehicleType,
      searchKey,
      make,
      models,
      bodyType,
      minPrice,
      maxPrice,
      minYear,
      maxYear,
      minMiles,
      maxMiles,
      isVerified,
      location,
      state,
      lat,
      lng,
      radius,
      sort,
      moreFiltersArr,
    ]
  );

  const getMakeData = async () => {
    if (router.asPath === "/") {
      return;
    }
    let data = await getInitMakeData();
    const makeArr = [];
    const resultData = {
      commonMakes: [],
      remainingMakes: [],
      ...(data?.results || {}),
    };
    for (let i = 0; i < resultData.commonMakes.length; i++) {
      makeArr.push(resultData.commonMakes[i]);
    }
    for (let i = 0; i < resultData.remainingMakes.length; i++) {
      makeArr.push(resultData.remainingMakes[i]);
    }
    setMakeData(makeArr);
    return makeArr;
  };

  const getData = async () => {
    if (data.TrackUrl) {
      updateQuery(isCleared ? ({} as any) : searchParameters);
    }
    setIsCleared(false);
    setIsFetchingListings(true);
    const response = await getPageData(
      rows,
      limit,
      current,
      vehicleType,
      searchKey,
      make,
      models,
      bodyType,
      minPrice,
      maxPrice,
      minYear,
      maxYear,
      minMiles,
      maxMiles,
      isVerified,
      moreFiltersArr,
      location,
      state,
      lat,
      lng,
      radius,
      sort
    );

    document.body.style.overflowY = "scroll";

    const ttl = response._meta.total;

    setTotal(ttl);
    imageFormat(response.data);
    imageFormat(response.similarListings);

    if (ttl === 0 && data.Redirected) {
      router.push(data.RedirectUrl);
    }

    if (response.data.length === 0 && response._meta.page > 0) {
      setCurrent(0);
      setIsFetchingListings(false);
      return;
    } else {
      setCurrent(response._meta.page);
    }
    setPageData(response.data);
    setSimilarData(response.similarListings);
    setIsFetchingListings(false);
  };

  const getLatLng = async () => {
    if (data.Filters?.Location != "" && data.Filters?.Location != undefined) {
      Geocode.setApiKey(googleGeocodeApiKey);
      Geocode.setLanguage("en");
      Geocode.setRegion("us");
      const response = await Geocode.fromAddress(
        data.Filters?.Location + ", USA"
      );
      const { lat, lng } = response.results[0].geometry.location;
      setLat(lat);
      setLng(lng);
      setRadius(20);
    }
  };

  const populateInitialLoad = () => {
    if (shouldUseStaticData) {
      routeHistoryStore.restoreScrollPosition(router.asPath);
    } else {
      getMakeData();
      getMoreFilterData();
    }
  };

  const getMoreFilterData = async () => {
    // let data = await getCarDetailsFilter();
  };

  useEffect(() => {
    const init = async () => {
      const setInitialVehicleType = () => {
        const type = syncedState.vehicleType || data.Filters?.VehicleType;
        if (type === "Car") {
          return "Auto";
        }
        return type || "All Vehicles";
      };
      setVehicleType(setInitialVehicleType());
      setMake(syncedState.make || data.Filters?.Make || "");
      setModels(syncedState.models || data?.Filters?.Model?.split() || []);
      setBodyType(
        syncedState.bodyType || data.Filters?.BodyType?.split() || []
      );
      setMinPrice(syncedState.minPrice ?? data.Filters?.PriceMin ?? MIN_PRICE);
      setMaxPrice(syncedState.maxPrice ?? data.Filters?.PriceMax ?? MAX_PRICE);
      setMinYear(syncedState.minYear ?? data?.Filters?.YearMin ?? MIN_YEAR);
      setMaxYear(syncedState.maxYear ?? data.Filters?.YearMax ?? MAX_YEAR);
      setMinMiles(syncedState.minMiles ?? data.Filters?.MilesMin ?? MIN_MILES);
      setMaxMiles(syncedState.maxMiles ?? data.Filters?.MilesMax ?? MAX_MILES);
      setCurrent(syncedState.current ?? 0);

      let moreFiltersPayload = {
        ...initFilters,
        ...pick(syncedState, [
          "fuelType",
          "exteriorColor",
          "interiorColor",
          "transmission",
          "driveType",
          "cylinders",
        ]),
      };
      if (data.Filters?.FuelType) {
        moreFiltersPayload.fuelType = [data.Filters.FuelType];
      }

      setMoreFiltersArr(moreFiltersPayload);

      if (data.Filters?.Location) {
        setLocation("");
        setState(data.Filters?.Location);
      } else {
        setLocation(
          syncedState.location ||
            (data.Filters?.Location ? `${data.Filters?.Location}, USA` : "")
        );
        setState(syncedState?.state || "");
      }
      setIsVerified(
        data.Filters?.Verification ?? syncedState?.isVerified ?? ""
      );
      getLatLng();
      setRadius(syncedState.radius ?? data.Filters?.Radius ?? 50);
      setSearchKey(syncedState.searchKey || "");
      populateInitialLoad();
      setTimeout(() => {
        setInitialDataState(
          shouldUseStaticData
            ? InitialDataState.loaded
            : InitialDataState.loading
        );
      }, 1000);
    };
    init();
  }, []);

  // listener is for initial fetch
  useEffect(() => {
    if (initialDataState === InitialDataState.loading) {
      getData().then(() => {
        routeHistoryStore.restoreScrollPosition(router.asPath);
        setInitialDataState(InitialDataState.loaded);
      });
    }
  }, [initialDataState]);

  // listener for succeeding fetch after initial fetch
  useEffect(() => {
    if (initialDataState === InitialDataState.loaded) {
      getData().then(() => {
        routeHistoryStore.restoreScrollPosition(router.asPath);
      });
    }
  }, [
    vehicleType,
    searchKey,
    make,
    models,
    bodyType,
    minPrice,
    maxPrice,
    minYear,
    maxYear,
    minMiles,
    maxMiles,
    isVerified,
    moreFiltersArr,
    state,
    location,
    lat,
    lng,
    radius,
    sort,
    current,
  ]);

  const checkIfFiltered = () => {
    return (
      vehicleType != "All Vehicles" ||
      searchKey != "" ||
      make != "" ||
      models.length != 0 ||
      bodyType.length != 0 ||
      minPrice != MIN_PRICE ||
      maxPrice != MAX_PRICE ||
      minYear != MIN_YEAR ||
      maxYear != MAX_YEAR ||
      minMiles != MIN_MILES ||
      minPrice != MIN_PRICE ||
      maxPrice != MAX_PRICE ||
      isVerified == verificationFilters.fullyVerified ||
      JSON.stringify(moreFiltersArr) != JSON.stringify(initFilters) ||
      location != ""
    );
  };

  const clearAll = () => {
    setVehicleType("All Vehicles");
    setSearchKey("");
    setMake("");
    setModels([]);
    setBodyType([]);
    setMinPrice(MIN_PRICE);
    setMaxPrice(MAX_PRICE);
    setMinYear(MIN_YEAR);
    setMaxYear(MAX_YEAR);
    setMinMiles(MIN_MILES);
    setMaxMiles(MAX_MILES);
    setIsVerified("");
    setMoreFiltersArr(initFilters);
    setState("");
    setLocation("");
    setLat(0);
    setLng(0);
    setRadius(50);
    setSort("Newest");
    setSelectedRadius(50);
    setSelectedPlace("");
    setSelectedState("");
    setIsOnlyState(false);
    setSelectedLat(0);
    setSelectedLng(0);
    setIsCleared(true);
  };

  const displayOffsitePageBreak = useMemo(() => {
    if (data.CombineOffSiteListings) {
      return false;
    }
    if (
      current === 0 &&
      pageData.length > 0 &&
      Boolean(pageData[0].originalSourceInfo)
    ) {
      return true;
    }
    return pageData.length > 0 && !pageData[0].originalSourceInfo;
  }, [pageData, current]);
  const mainListings = useMemo(
    () =>
      displayOffsitePageBreak
        ? pageData?.filter((listing: any) => !listing?.originalSourceInfo)
        : pageData,
    [pageData, displayOffsitePageBreak]
  );
  const offSiteListings = useMemo(
    () =>
      displayOffsitePageBreak
        ? pageData?.filter((listing: any) => listing?.originalSourceInfo) ?? []
        : [],
    [pageData, displayOffsitePageBreak]
  );

  return (
    <div className="relative w-full">
      <div className="w-full z-0">
        {data.AllowFilterChanges && (
          <Filter
            makeData={makeData}
            moreFilterData={moreFilterData}
            clearAll={clearAll}
            isFilterDisable={!data.AllowFilterChanges}
            currentPage={current}
            exactCnt={total}
            rows={rows}
            checkIfFiltered={checkIfFiltered}
            pageSize={pageSize}
          />
        )}
        {!data.AllowFilterChanges &&
          (data.Title != null || data.LinkText != null) && (
            <div className="w-full text-center pb-4">
              <UniversalHTag
                tag={data.TitleStyle}
                classname={`${parseTitle(data.TitleStyle)} text-[#333] ${
                  checkHomePage() ? "pt-6 sm:pt-12" : "pt-12"
                } pb-6`}
                content={
                  data.Title != undefined && data.Title != null && data.Title
                }
              />
              <Link
                href={data.LinkURL || ""}
                className="text-[#00b3de] text-[20px]"
              >
                {data.LinkText}
              </Link>
            </div>
          )}
        <div
          className={`w-full ${
            data.AllowFilterChanges
              ? "bg-[#f8f8f8]"
              : checkHomePage()
              ? "sm:mb-16"
              : "mb-16"
          }`}
        >
          {isFetchingListings ? (
            <VehicleListingsCardSkeleton />
          ) : total == 0 ? (
            <NoMatch clearAll={clearAll} />
          ) : checkHomePage() ? (
            <div
              className={`w-full lg:max-w-[1200px] px-2 vs:px-[8%] lg:px-0 mx-auto overflow-y-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 ${
                !data.AllowFilterChanges
                  ? "py-8"
                  : total <= pageSize
                  ? "bg-[#f8f8f8] py-4"
                  : "bg-[#f8f8f8]"
              }`}
            >
              {mainListings.map((item: any, index: number) => (
                <VehicleCard
                  data={item}
                  index={index}
                  key={item._id}
                  isNative={isNative}
                />
              ))}
            </div>
          ) : (
            mainListings.length > 0 && (
              <div
                className={`w-full lg:max-w-[1140px] px-2 vs:px-[8%] lg:px-0 mx-auto overflow-y-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 ${
                  !data.AllowFilterChanges
                    ? "py-8"
                    : total <= pageSize
                    ? "bg-[#f8f8f8] py-4"
                    : "bg-[#f8f8f8]"
                }`}
              >
                {mainListings.map((item: any, index: number) => (
                  <VehicleCard
                    data={item}
                    index={index}
                    key={item._id}
                    isNative={isNative}
                  />
                ))}
              </div>
            )
          )}
          {offSiteListings.length > 0 && (
            <div className="w-full lg:max-w-[1140px] px-4 vs:px-[8%] lg:px-0 mx-auto">
              <div className="w-full pt-8">
                {mainListings.length > 0 && (
                  <hr className="w-full border text-[#dfdfdf] mb-5" />
                )}
                <div className="w-full px-2 py-5 bg-[#FFEDDE] text-center mb-10">
                  <span className="text-lg font-semibold">
                    Off-site listings
                  </span>
                  <p className="text-base font-normal">
                    Private party vehicles pulled from other websites making car
                    shopping easier. Use{" "}
                    <Link className="underline" href="/dealnow">
                      DealNow
                    </Link>{" "}
                    to safely transact.
                  </p>
                </div>
              </div>
              <div
                className={`w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 ${
                  !data.AllowFilterChanges
                    ? "py-4"
                    : total <= pageSize
                    ? "bg-[#f8f8f8] py-4"
                    : "bg-[#f8f8f8]"
                }`}
              >
                {offSiteListings.map((item: any, index: number) => (
                  <VehicleCard
                    data={item}
                    index={index}
                    key={item._id}
                    isNative={isNative}
                  />
                ))}
              </div>
            </div>
          )}

          <div className="flex justify-center mt-6">
            {data?.CTAs?.data?.length > 0 && (
              <CTA
                data={data}
                searchParameters={{
                  rows,
                  limit,
                  current,
                  vehicleType,
                  searchKey,
                  make,
                  models,
                  bodyType,
                  minPrice,
                  maxPrice,
                  minYear,
                  maxYear,
                  minMiles,
                  maxMiles,
                  isVerified,
                  moreFiltersArr,
                  location,
                  state,
                  lat,
                  lng,
                  radius,
                  sort,
                }}
              />
            )}
          </div>
          {similarIsShow != null &&
            similarIsShow &&
            similarData.length != 0 && (
              <div className="w-full lg:max-w-[1140px] px-4 vs:px-[8%] lg:px-0 mx-auto">
                <div className="w-full pt-8">
                  <hr className="w-full border text-[#dfdfdf] mb-5" />
                  <div className="w-full px-2 py-5 bg-[#FFFFFF] text-center mb-10">
                    <span className="text-lg font-semibold">
                      Other similar vehicles to check out
                    </span>
                  </div>
                </div>
                <div
                  className={`w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 ${
                    !data.AllowFilterChanges
                      ? "py-4"
                      : total <= pageSize
                      ? "bg-[#f8f8f8] py-4"
                      : "bg-[#f8f8f8]"
                  }`}
                >
                  {similarData.map((item: any, index: number) => (
                    <VehicleCard
                      data={item}
                      index={index}
                      key={item._id}
                      isNative={isNative}
                    />
                  ))}
                </div>
              </div>
            )}
          {total > 0 &&
            total > pageSize &&
            (data.ShowPagination === null || data.ShowPagination) && (
              <Pagination
                pages={Math.ceil(total / pageSize) - 1}
                currentPage={current}
                total={total}
                rows={rows}
                pageSize={pageSize}
                onClick={setCurrent}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default VehicleSearch;
