import Design1VinEntry from "@/components/sell/Design1VinEntry";
import Design2VinEntry from "@/components/sell/Design2VinEntry";

type Props = {
  data: any;
};

const SellEntry = ({ data }: Props) => {
  return <Design2VinEntry data={data} />;
};

export default SellEntry;
