import { useEffect, useState } from "react";
import Image from "next/image";
import { initalCarsArray } from "@/libs/constants";

type Props = {
  bodyType: Array<string>;
  setBodyType: Function;
  triggerReset: boolean;
};

const CarTypeSection = ({ bodyType, setBodyType, triggerReset }: Props) => {
  let initArray = initalCarsArray.slice().map((item: any) => {
    if (bodyType.includes(item.type)) {
      return {
        ...item,
        isSelected: true,
      };
    } else {
      return item;
    }
  });
  const [cars, SetCars] = useState<any>(initArray);

  useEffect(() => {
    if (!triggerReset) return;
    let bodyArray = initalCarsArray.slice().map((item: any) => {
      return {
        ...item,
        isSelected: false,
      };
    });
    SetCars(bodyArray);
  }, [triggerReset]);

  const carHandle = (type: string, selected: Boolean) => {
    let _cars = cars.slice().map((item: any) => {
      if (item.type == type) {
        return {
          ...item,
          isSelected: selected,
        };
      } else {
        return item;
      }
    });
    SetCars(_cars);
    const data: any = [];
    for (let item of _cars) {
      if (item.isSelected) {
        data.push(item.type);
      }
    }
    setBodyType(data);
  };

  return (
    <div className="text-base text-[#212529]">
      <div className="text-left text-lg text-[#333] font-semibold py-4">
        Body type
      </div>
      <div className="grid grid-cols-3 gap-x-10">
        {cars.map((car: any, index: number) => {
          return (
            <div key={index}>
              {car.isSelected ? (
                <div
                  className="bg-[#e2e8f0] py-1 mb-4 rounded"
                  onClick={() => {
                    carHandle(car.type, false);
                  }}
                >
                  <div className="w-full flex justify-center">
                    <Image
                      src={`/assets/car-icons/${car.img}`}
                      className=""
                      width={70}
                      height={28}
                      alt={car.type || ""}
                      key={index}
                    />
                  </div>
                  <div className="text-xs font-semibold text-center cursor-default">
                    {car.type}
                  </div>
                </div>
              ) : (
                <div
                  className="py-1 mb-4"
                  key={index}
                  onClick={() => {
                    carHandle(car.type, true);
                  }}
                >
                  <div className="w-full flex justify-center">
                    <Image
                      src={`/assets/car-icons/${car.img}`}
                      className=""
                      width={70}
                      height={28}
                      alt={car.type || ""}
                      key={index}
                    />
                  </div>
                  <div className="text-xs font-semibold text-center cursor-default">
                    {car.type}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <hr className="w-full" />
    </div>
  );
};

export default CarTypeSection;
