import { useEffect, useRef, useState } from "react";
import { sortArray } from "@/libs/constants";
import { useSearchValue } from "@/contexts/contextSearch";
import useOutsideClick from "@/hooks/useOutsideClick";
import { useRouter } from "next/router";
import { checkNativePage, isMobileDevice, isWebView } from "@/libs/utils";
import { useVintageForm } from "@/hooks/useVintageForm";
import { useAuthValue } from "@/contexts/contextAuth";
import Image from "next/image";
import { Tooltip } from "react-tooltip";
import { motion } from "framer-motion";

type Props = {
  children: any;
  modalRef: any;
  modalConfig: ModalConfig;
};

export interface ModalConfig {
  showClosingX: boolean;
  onClose: () => void;
}

const Modal = ({ modalRef, children, modalConfig }: Props) => {
  return (
    <motion.div
      initial={{ y: -30 }}
      animate={{ y: 0 }}
      exit={{ y: -30, transition: { duration: 0 } }}
      transition={{ duration: 0.2 }}
      className="fixed top-0 left-0 w-full h-screen z-[10000000] flex items-center justify-center bg-black bg-opacity-60  inset-x-0 bottom-0"
    >
      <div
        className="rounded-xl lg:rounded-xl rounded-bl-none rounded-br-none sm:rounded-bl-xl sm:rounded-br-xl w-full bg-white text-[#333] shadow-md rounded-xl py-4 z-[100] align-bottom shadow-xl transition-all  align-middle fixed inset-x-0 bottom-0 sm:relative sm:my-8 sm:align-middle lg:w-[490px]"
        ref={modalRef}
      >
        {modalConfig.showClosingX && (
          <div
            className="flex justify-end pr-3"
            onClick={() => modalConfig.onClose()}
          >
            <Image
              src={"/assets/close.svg"}
              width={30}
              height={30}
              alt="close"
            />
          </div>
        )}

        <div className="w-full pb-10 pt-4 px-4 sm:px-12">{children}</div>
      </div>
    </motion.div>
  );
};

export default Modal;
