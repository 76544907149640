import Image from "next/image";

const getCellData = (data: any, isHeader: Boolean): React.ReactNode => {
  if (data.__component == "sub-elements.cell-simple") {
    return (
      <p className={`text-[#4f4f4f] ${isHeader && "mt-10"}`}>{data.Value}</p>
    );
  } else if (data.__component == "sub-elements.cell-header") {
    return (
      <p className={`font-semibold ${isHeader && "mt-10"}`}>{data.Value}</p>
    );
  } else if (data.__component == "sub-elements.cell-image") {
    return (
      <div className="w-full flex justify-center items-center">
        <Image
          width={data.Image?.data?.attributes.width}
          height={data.Image?.data?.attributes.height}
          src={data.Image?.data?.attributes.url}
          alt={data.Image?.Description || ""}
        />
      </div>
    );
  } else return "";
};

export default getCellData;
