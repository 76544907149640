const VerifiedIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="12"
        cy="12"
        r="11.5"
        fill={props.color}
        stroke={props.bordercolor}
      />
      <path d="M6 12.75L10 16.5L18 7.5" stroke="white" strokeWidth="1.5" />
    </svg>
  );
};

export default VerifiedIcon;
