import { useEffect, useState } from "react";
import { parseTitle, parseBgColor } from "@/libs/utils";
import UniversalHTag from "@/libs/UniversalHTag";

type Props = {
  data: any;
};

const SalesTaxCalc = ({ data }: Props) => {
  const [vehiclePrice, setVehiclePrice] = useState<number>(20000);
  const [salesTaxRate, setSalesTaxRate] = useState<number>(0.0);
  const [salesTaxRateString, setSalesTaxRateString] = useState<string>("2.99%");

  const [countySalesTaxRate, setCountySalesTaxRate] = useState<number>(2.99);
  const [countySalesTaxRateString, setCountySalesTaxRateString] =
    useState<string>("2.99%");

  const [estimatedSalesTax, setEstimatedSalesTax] = useState<number>(0);
  const [totalVehicleCost, setTotalVehicleCost] = useState<number>(0);

  const calculateSalesTax = () => {
    const decimalSalesTaxRate = salesTaxRate / 100;
    const decimalCountySalesTaxRate = countySalesTaxRate / 100;
    const stateSalesTax = vehiclePrice * decimalSalesTaxRate;
    const countySalesTax = vehiclePrice * decimalCountySalesTaxRate;
    const totalSalesTax = stateSalesTax + countySalesTax;
    setEstimatedSalesTax(totalSalesTax);
    const totalCost = vehiclePrice + totalSalesTax;
    setTotalVehicleCost(totalCost);
  };

  useEffect(() => {
    calculateSalesTax();
  }, [vehiclePrice, salesTaxRate, countySalesTaxRate]);

  const states = data.States.data;

  return (
    <div
      className={`w-full my-6  ${parseBgColor(
        data.Background.toLowerCase()
      )} h-auto`}
    >
      <div className="w-full">
        <UniversalHTag
          tag={data.TitleStyle}
          classname={`w-full text-2xl ${parseTitle(
            data.TitleStyle
          )} text-center text-white opacity-90 pt-8`}
          content={data.Title}
        />
      </div>
      {data.SubTitle && (
        <div className="w-full">
          <UniversalHTag
            tag={data.SubTitleStyle}
            classname={`w-full text-center text-xl ${parseTitle(
              data.SubTitleStyle
            )} py-3 md:py-8`}
            content={data.SubTitle}
          />
        </div>
      )}

      <div className="w-full block sm:flex justify-center  space-x-0 sm:space-x-20 px-10 mt-[20px] pb-[40px] space-y-8 sm:space-y-0">
        <div
          className={`w-full sm:w-1/3 md:w-1/4 bg-white px-8 py-6 space-y-4 border-2 border-[#e6e6e6] border-solid rounded-lg`}
        >
          <div className="w-full space-y-5">
            <label className="text-[#083829] font-semibold">
              Vehicle Price
            </label>
            <input
              type="text"
              className="form-control block w-full px-4 py-2 text-sm font-medium bg-white bg-clip-padding border border-solid border-[#9797aa] rounded focus:border-[#a3b0ca] focus:text-gray-700 focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all"
              value={vehiclePrice.toLocaleString()}
              onChange={(e: any) => {
                setVehiclePrice(Number(e.target.value.replace(/\D/g, "")));
              }}
            />
          </div>

          <div className="w-full space-y-5">
            <label className="text-[#083829] font-semibold">State</label>
            <select
              className="form-control block w-full px-3 py-2 text-sm font-medium text-[#063829] bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-700 focus:outline-none cursor-pointer"
              onChange={(e) => {
                const selectedState = states.find(
                  (state: any) => state.id === Number(e.target.value)
                );

                if (selectedState) {
                  setSalesTaxRate(selectedState.attributes.SalesTax || 0);
                  setSalesTaxRateString(
                    `${selectedState.attributes.SalesTax}%` || "0%"
                  );
                }
              }}
            >
              {states.map((state: any, index: number) => (
                <option key={index} value={state.id}>
                  {state.attributes.Name}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full space-y-5">
            <label className="text-[#083829] font-semibold">
              Sales Tax Rate
            </label>
            <div className="relative">
              <input
                type="text"
                className="form-control block w-full px-4 py-2 text-sm font-medium bg-white bg-clip-padding border border-solid border-[#9797aa] rounded m-0 focus:border-[#a3b0ca] focus:text-gray-700 focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all"
                value={salesTaxRateString}
                onChange={(e: any) => {
                  if (
                    e.target.value.length === 1 ||
                    e.target.value.indexOf("%") > 0
                  ) {
                    let rate = e.target.value.slice(
                      0,
                      e.target.value.length - 1
                    );
                    if (
                      typeof Number(rate) === "number" &&
                      !isNaN(Number(rate))
                    ) {
                      if (Number(rate) > 100) {
                        rate = 100;
                        setSalesTaxRate(100);
                      }
                      if (Number(rate) < 0) {
                        rate = 0;
                        setSalesTaxRate(0);
                      } else {
                        setSalesTaxRate(rate);
                      }
                      setSalesTaxRateString(rate + "%");
                    }
                  }
                }}
              />
            </div>
          </div>
          <div className="w-full space-y-5">
            <label className="text-[#083829] font-semibold">
              County Sales Tax Rate
            </label>
            <div className="relative">
              <input
                type="text"
                className="form-control block w-full px-4 py-2 text-sm font-medium bg-white bg-clip-padding border border-solid border-[#9797aa] rounded m-0 focus:border-[#a3b0ca] focus:text-gray-700 focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all"
                value={countySalesTaxRateString}
                onChange={(e: any) => {
                  if (
                    e.target.value.length === 1 ||
                    e.target.value.indexOf("%") > 0
                  ) {
                    let rate = e.target.value.slice(
                      0,
                      e.target.value.length - 1
                    );
                    if (
                      typeof Number(rate) === "number" &&
                      !isNaN(Number(rate))
                    ) {
                      if (Number(rate) > 100) {
                        rate = 100;
                        setCountySalesTaxRate(100);
                      }
                      if (Number(rate) < 0) {
                        rate = 0;
                        setCountySalesTaxRate(0);
                      } else {
                        setCountySalesTaxRate(rate);
                      }
                      setCountySalesTaxRateString(rate + "%");
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={`w-full sm:w-1/3 md:w-1/4 bg-white text-center px-8 py-6 space-y-4 border-2 border-[#e6e6e6] border-solid rounded-lg`}
        >
          <p className="text-[#595c5f]">Estimated Car Sales Tax</p>
          <p className="font-semibold text-3xl">
            ${estimatedSalesTax.toLocaleString()}
          </p>
          <p className="pb-4">{`Total vehicle cost: $${totalVehicleCost}`}</p>
          <hr className="text-[#d2d2d2] border-[1.5px]" />
          <div className="text-left text-sm text-[#154335] space-y-4">
            <div className="font-semibold">Car Sales Tax Summary</div>
            <div className="w-full flex">
              <p className="w-[70%]">Vehicle price</p>
              <p className="w-[30%]">${vehiclePrice}</p>
            </div>
            <div className="w-full flex">
              <p className="w-[70%]">Sales Tax Rate</p>
              <p className="w-[30%]">{salesTaxRate}%</p>
            </div>
            <div className="w-full flex items-center">
              <p className="w-[70%]">County Tax Rate</p>
              <p className="w-[30%]">{countySalesTaxRate}%</p>
            </div>
            <hr className="text-[#d2d2d2] border-[1.5px]" />
            <div className="w-full flex">
              <p className="w-[70%] text-[#565656]">Total Sales Tax</p>
              <p className="w-[30%]">${estimatedSalesTax.toFixed()}</p>
            </div>
            <div className="w-full flex">
              <p className="w-[70%] text-black font-semibold">
                Total Vehicle Cost
              </p>
              <p className="w-[30%]">${totalVehicleCost.toFixed()}</p>
            </div>
          </div>
          <div className="text-left text-xs text-[#a5a5a5]">{data.Content}</div>
        </div>
      </div>
    </div>
  );
};

export default SalesTaxCalc;
