import { useState } from "react";
import {
  parseTitle,
  convertBgPaymentCalculator,
  convertStylePaymentCalculator,
} from "@/libs/utils";
import UniversalHTag from "@/libs/UniversalHTag";

type Props = {
  data: any;
};

const PaymentCalculator = ({ data }: Props) => {
  const [vehiclePrice, setVehiclePrice] = useState<number>(20000);
  const [downPayment, setDownPayment] = useState<number>(1500);
  const [interestRate, setInterestRate] = useState<number>(7.5);
  const [loanTerm, setLoanTerm] = useState<number>(72);
  const [interestRateStr, setInterestRateStr] = useState<string>("7.5%");
  const loanPayment = vehiclePrice - downPayment;
  const interest = (loanPayment * (interestRate * 0.01)) / loanTerm;
  const monthlyPayment = (loanPayment / loanTerm + interest).toFixed(2);

  return (
    <div className="relative w-full my-6 h-[1300px] sm:h-[800px]">
      <div
        className={`w-full ${convertStylePaymentCalculator(
          data?.BackgroundStyle
        )} ${
          data?.Background == null
            ? "bg-[#45577d]"
            : convertBgPaymentCalculator(data.Background)
        }`}
      >
        <UniversalHTag
          tag={data.TitleStyle}
          classname={`w-full text-2xl ${parseTitle(
            data.TitleStyle
          )} text-center text-white opacity-90 pt-8`}
          content={data.Title}
        />
      </div>
      <div className="absolute top-40 w-full">
        <div className="sm:flex justify-center sm:space-x-20 px-4 xs:px-10 space-y-8 sm:space-y-0">
          <div
            className={`w-full xs:max-w-[360px] mx-auto sm:mx-0 bg-white px-8 py-6 space-y-4 border-2 border-[#e6e6e6] border-solid rounded-lg`}
          >
            <div className="w-full space-y-2">
              <label className="text-[#083829] font-semibold">
                Vehicle Price
              </label>
              <input
                type="text"
                className="form-control block w-full px-4 py-2 text-sm font-medium bg-white bg-clip-padding border border-solid border-[#9797aa] rounded focus:border-[#a3b0ca] focus:text-gray-700 focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all"
                value={vehiclePrice.toLocaleString()}
                onChange={(e: any) => {
                  setVehiclePrice(Number(e.target.value.replace(/\D/g, "")));
                }}
              />
            </div>
            <div className="w-full space-y-2">
              <label className="text-[#083829] font-semibold">
                Down Payment
              </label>
              <input
                type="text"
                className="form-control block w-full px-4 py-2 text-sm font-medium bg-white bg-clip-padding border border-solid border-[#9797aa] rounded m-0 focus:border-[#a3b0ca] focus:text-gray-700 focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all"
                value={downPayment.toLocaleString()}
                onChange={(e: any) => {
                  setDownPayment(Number(e.target.value.replace(/\D/g, "")));
                }}
              />
            </div>
            <div className="w-full space-y-2">
              <label className="text-[#083829] font-semibold">
                Credit Score
              </label>
              <select className="form-control block w-full px-3 py-2 text-sm font-medium text-[#063829] bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-700 focus:outline-none cursor-pointer">
                <option value="Poor(300-579)">Poor(300-579)</option>
                <option value="Fair(580-669)">Fair(580-669)</option>
                <option value="Good(670-739)">Good(670-739)</option>
                <option value="Very Good(740-799)">Very Good(740-799)</option>
                <option value="Excellent(800-850)">Excellent(800-850)</option>
              </select>
            </div>
            <div className="w-full space-y-2">
              <label className="text-[#083829] font-semibold">
                Interest Rate
              </label>
              <input
                type="text"
                className="form-control block w-full px-4 py-2 text-sm font-medium bg-white bg-clip-padding border border-solid border-[#9797aa] rounded m-0 focus:border-[#a3b0ca] focus:text-gray-700 focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all"
                value={interestRateStr}
                onChange={(e: any) => {
                  if (
                    e.target.value.length === 1 ||
                    e.target.value.indexOf("%") > 0
                  ) {
                    let rate = e.target.value.slice(
                      0,
                      e.target.value.length - 1
                    );
                    if (
                      typeof Number(rate) === "number" &&
                      !isNaN(Number(rate))
                    ) {
                      if (Number(rate) > 100) {
                        rate = 100;
                        setInterestRate(100);
                      }
                      if (Number(rate) < 0) {
                        rate = 0;
                        setInterestRate(0);
                      } else {
                        setInterestRate(rate);
                      }
                      setInterestRateStr(rate + "%");
                    }
                  }
                }}
              />
            </div>
            <div className="w-full space-y-2">
              <label className="text-[#083829] font-semibold">Loan Term</label>
              <select
                className="form-control block w-full px-3 py-2 text-sm font-medium text-[#063829] bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-700 focus:outline-none cursor-pointer"
                onChange={(e) => {
                  setLoanTerm(Number(e.target.value));
                }}
              >
                <option value="12">12 months</option>
                <option value="24">24 months</option>
                <option value="36">36 months</option>
                <option value="48">48 months</option>
                <option value="60">60 months</option>
                <option value="72">72 months</option>
              </select>
            </div>
          </div>
          <div
            className={`w-full xs:max-w-[360px] mx-auto sm:mx-0 bg-white text-center px-8 py-6 space-y-4 border-2 border-[#e6e6e6] border-solid rounded-lg`}
          >
            <p className="pt-8 text-[#595c5f]">Estimated monthly payment</p>
            <p className="font-semibold text-3xl">
              ${monthlyPayment.toLocaleString()}
              <span className="font-bold text-lg">/mo</span>
            </p>
            <p className="pb-4">{`for ${loanTerm / 12} years`}</p>
            <hr className="text-[#d2d2d2] border-[1.5px]" />
            <div className="text-left text-sm text-[#154335] space-y-4">
              <div className="font-semibold">Loan Summary</div>
              <div className="w-full flex">
                <p className="w-[70%]">Vehicle Budget</p>
                <p className="w-[30%]">${vehiclePrice.toLocaleString()}</p>
              </div>
              <div className="w-full flex">
                <p className="w-[70%]">Down Payment</p>
                <p className="w-[30%]">-${downPayment.toLocaleString()}</p>
              </div>
              <hr className="text-[#d2d2d2] border-[1.5px]" />
              <div className="w-full flex">
                <p className="w-[70%] text-[#565656]">Total Loan Amount</p>
                <p className="w-[30%]">${loanPayment.toLocaleString()}</p>
              </div>
              <div className="w-full flex">
                <p className="w-[70%] text-black font-semibold">
                  Monthly Payment
                </p>
                <p className="w-[30%]">${monthlyPayment.toLocaleString()}/mo</p>
              </div>
            </div>
            <div className="text-left text-xs text-[#a5a5a5]">
              {data.Content}
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center py-8">
          <div className="w-4/5 sm:w-1/3 text-xs text-center text-[#ccc] font-normal">
            *Disclaimer: The estimated payment is calculated using the vehicle
            price (excluding taxes and fees), along with the applicable lender
            rates for eligible buyers, the duration of the loan, and any
            trade-in value or down payment provided by the buyer. The acceptance
            of these terms by the seller is necessary for the agreement. Please
            note that the specific terms may differ.
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCalculator;
