import Image from "next/image";
import Link from "next/link";

const DealStartNowSection = () => {
  const vehicleTypeIcons = [
    {
      vehicleImage: "/assets/car-icons/car-border-blue.svg",
      alt: "car",
    },
    {
      vehicleImage: "/assets/car-icons/motorcycle-border-blue.svg",
      alt: "motorcycle",
    },
    {
      vehicleImage: "/assets/car-icons/atv-border-blue.svg",
      alt: "ATV",
    },
    {
      vehicleImage: "/assets/car-icons/rv-border-blue.svg",
      alt: "RV",
    },
    {
      vehicleImage: "/assets/car-icons/boat-border-blue.svg",
      alt: "boat",
    },
    {
      vehicleImage: "/assets/car-icons/trailer-border-blue.svg",
      alt: "trailer",
    },
  ];

  return (
    <div className="relative w-full bg-[#EFF0F6] bg-opacity-100">
      <div className="flex flex-col justify-center items-center w-full py-10">
        <Image
          src={"/assets/deal-now/deal-now-logo.svg"}
          width={210}
          height={38}
          className="py-8"
          alt="dealNow logo"
        />

        <div className="md:text-[40px] text-[24px] font-[700] max-w-[700px] text-center px-4">
          Skip the listing and transact on any vehicle, anytime.
        </div>

        <Link
          className="w-[166px] bg-[#00B3DE] hover:opacity-80 border border-[#00A0C7] text-white text-base text-center font-bold py-2 px-4 rounded cursor-pointer mt-6"
          href="https://dealnow.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </Link>
        <div className="grid grid-cols-3 md:gap-8 gap-4 md:grid-cols-6  px-4 mt-8">
          {vehicleTypeIcons.map((icon) => (
            <Image
              key={icon.alt}
              alt={icon.alt}
              src={icon.vehicleImage}
              width={110}
              height={75}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DealStartNowSection;
