import { useAuthValue } from "@/contexts/contextAuth";
import useAuth from "@/hooks/useAuth";
import { convertSearchToAppSearch } from "@/libs/utils";
import Image from "next/image";
import { useRouter } from "next/router";
import qs from "qs";

type Props = {
  clearAll: Function;
};

const NoMatch = ({ clearAll }: Props) => {
  const { setIsSignup, setRedirectUrl } = useAuthValue();
  const router = useRouter();
  const onSaveSearch = () => {
    const payload = {
      ...convertSearchToAppSearch(qs.parse(router.query as any) as any),
      saveSearch: true,
    };
    const redirectUrl = `/search-listings?${qs.stringify(payload)}`;
    setRedirectUrl(redirectUrl);
    setIsSignup(true, { redirectUrl });
  };
  return (
    <section className="w-full bg-[#f8f8f8] py-6">
      <div className="w-full text-center text-[#000]">
        <p className="text-xl font-semibold py-1">
          We didn&apos;t find any matches
        </p>
        <p className="text-sm">
          Try changing your search criteria or remove filters.
        </p>
      </div>
      <div className="w-full flex justify-center py-6">
        <button
          className="bg-white hover:opacity-80 text-sm text-[#00b3de] font-medium py-2 px-4 border border-[#00b3de] rounded"
          onClick={() => clearAll()}
        >
          Clear search
        </button>
      </div>
      <div className="relative w-full h-[244px]">
        <div className="absolute top-0 left-0 w-full h-full bg-[#2F3B55] opacity-80 z-10" />
        <Image
          width={1200}
          height={244}
          src="/assets/no-results.svg"
          alt="no-results"
          className="object-cover absolute w-full h-full"
        />
        <div className="flex absolute justify-center items-center w-full h-full bg-slate-700 bg-opacity-80 z-20 text-white text-center">
          <div className="py-10 flex flex-col h-full w-[300px] items-center">
            <div className="text-3xl font-bold leading-10">
              Save your search
            </div>
            <div className="mt-5 text-base leading-6">
              Get notified when any new vehicles are posted with this search.
            </div>
            <button
              type="button"
              onClick={onSaveSearch}
              className="mt-5 bg-white text-[#2F3B55] font-medium px-4 py-3 w-[156px] flex justify-center items-center gap-2 rounded"
            >
              Save this search
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NoMatch;
