import { useCallback, useState } from "react";
import Image from "next/image";
import { ArticleCard } from "./ArticleCard";

interface Props {
  data: any;
}

export const AuthorBlogs = ({ data }: Props) => {
  return (
    <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4 justify-center py-6 h-auto max-w-5xl mx-auto">
      {data.Articles.map((article: any, index: number) => (
        <ArticleCard data={article} key={index} />
      ))}
    </div>
  );
};
