import Image from "next/image";
import { useState, useEffect, useRef } from "react";

interface Props {
  data: {
    image: string;
    title: string;
    author: string;
    authorPhoto: string;
    publishedAt: string;
    slug: string;
    articleContent: string;
  };
}

const formatDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(date).toLocaleDateString(undefined, options);
};

export const ArticleCard = ({ data }: Props) => {
  const formattedPublishedAt = formatDate(new Date(data?.publishedAt));
  const contentRef = useRef<HTMLDivElement>(null);
  const [showContent, setShowContent] = useState(true);

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.clientHeight;
      const availableHeight = 215;
      if (contentHeight > availableHeight) {
        setShowContent(false);
      }
    }
  }, []);

  return (
    <a
      href={data?.slug}
      rel="noopener"
      target="_blank"
      className="w-auto h-[350px] bg-[#F5F6F7] rounded-xl flex flex-col"
    >
      <div className="h-[142px]">
        <Image
          width={285}
          height={142}
          src={data.image}
          alt={data.title}
          className="w-full h-full object-cover rounded-t"
        />
      </div>

      <div className="flex flex-col h-auto p-4 flex-grow" ref={contentRef}>
        <div>
          <h3 className="text-[20px] font-[700] mb-2">{data.title}</h3>
          <hr className="border-neutral-200" />
        </div>

        {showContent && (
          <div className="text-[#4F4F4F] text-[15px] font-[400] pt-2 overflow-hidden flex-grow">
            {data.articleContent
              ?.slice(0, 70)
              .split(" ")
              .slice(0, -1)
              .join(" ")}
            ...
          </div>
        )}

        <div className="mt-auto">
          <div className="flex items-center text-[#828282]">
            <div className="flex items-center relative">
              <Image
                src={data?.authorPhoto}
                alt="Profile Picture"
                width={30}
                height={30}
                className="rounded-full w-[30px] h-[30px] overflow-hidden"
              />
            </div>
            <div className="flex items-center pl-3 text-[13px] font-[500]">
              <div>{data.author}</div>
              <div className="px-1">&middot;</div>
              <div>{formattedPublishedAt}</div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};
