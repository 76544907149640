import { checkNewSellPages } from "@/libs/utils";

type Props = {
  data: any;
};

const Disclaimer = ({ data }: Props) => {
  return (
    <div className="w-full flex justify-center">
      <div
        className={`w-4/5 sm:w-1/3 text-xs text-center ${
          checkNewSellPages() ? "text-[#FFFF]" : "text-[#6c757d]"
        } font-normal ${
          data.Background?.toLowerCase() === "dark blue" &&
          data.BackgroundLower?.toLowerCase() !== "white"
            ? "text-[#FFFFFF]"
            : ""
        }`}
      >
        *Disclaimer: Account services are provided by USALLIANCE Financial, a
        licensed and insured banking institution. PrivateAuto does not
        facilitate or handle any funds throughout the transaction. All funds in
        custody of USALLIANCE. Buyer financing may require additional fees.
      </div>
    </div>
  );
};

export default Disclaimer;
