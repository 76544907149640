import { useCallback, useState } from "react";
import Image from "next/image";

interface Props {
  data: any;
}

export const AuthorBio = ({ data }: Props) => {
  const { AuthorBio, AuthorName, LinkedInLink, TwitterLink, Headshot } =
    data.author?.data?.attributes;
  const { url } = Headshot.data.attributes;

  return (
    <div className="w-full flex flex-col sm:flex-row p-4 justify-center py-6 h-auto max-w-5xl mx-auto">
      <div className="h-auto border rounded-lg text-center sm:mr-4 mb-4 sm:mb-0 flex-shrink-0">
        <div className="px-11 pt-11 flex justify-center">
          <Image
            className="rounded-lg"
            src={url}
            width={250}
            height={230}
            alt={"author image"}
          />
        </div>
        <div className="py-4">
          <div className="text-[32px] font-700">{AuthorName}</div>
          <div className="text-[24px] font-500">Contributing Author</div>
        </div>
      </div>

      <div className="flex flex-col justify-between h-auto flex-grow">
        <div className="text-[20px] font-500 mb-4 sm:mb-0 leading-10">
          {AuthorBio}
        </div>
        <div className="flex items-center">
          connect:
          {LinkedInLink && (
            <a
              className="pl-2"
              rel="noopener"
              target="_blank"
              href={LinkedInLink}
            >
              <Image
                src={"/assets/blackLinkedinLogo.svg"}
                height={25}
                width={25}
                alt="Linkedin Logo"
              />
            </a>
          )}
          {TwitterLink && (
            <a
              className="pl-2"
              rel="noopener"
              target="_blank"
              href={TwitterLink}
            >
              <Image
                src={"/assets/blackTwitterLogo.svg"}
                height={25}
                width={25}
                alt="Twitter Logo"
              />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
