import { useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { parseBgColor } from "@/libs/utils";

type Props = {
  data: any;
};

function safeUrl(base: string, path: string): string {
  return ((path || "") + (base || "")).replace("//", "/");
}

const ListTab = ({ data }: Props) => {
  const router = useRouter();
  const [selectedTab, setSelectedTab] = useState<number>(data.Default);
  const { asPath } = router;
  let pathname = asPath;
  data.Items.map((item: any) => {
    if (asPath.includes(item.URL)) {
      pathname = pathname.replace(item.URL, "");
    }
  });
  return (
    <div
      className={`w-full ${parseBgColor(
        data.Color
      )} px-0 xs:px-10 md:px-[10%] pt-[80px]`}
    >
      <div className="w-full lg:max-w-[1140px] flex justify-center sm:px-[8%] lg:px-0 mx-auto my-6">
        {data.Items.map((item: any, index: number) => {
          return (
            <Link
              className="w-1/2"
              href={safeUrl(item.URL, pathname)}
              key={index}
            >
              <div
                className={`${
                  selectedTab == index + 1
                    ? "bg-white text-[#2e3b54] border-t-4 border-[#2e3b54]"
                    : `${parseBgColor(data.Color)} text-[#828282]`
                } w-full text-center text-2xl font-semibold py-5 cursor-pointer`}
                onClick={() => setSelectedTab(index + 1)}
              >
                {item.Item}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default ListTab;
