const CircleRightIcon = (props: any) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="18"
        cy="18"
        r="17"
        fill="white"
        stroke={props.color}
        strokeWidth="2"
      />
      <path
        d="M15 24L21 18L15 12"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CircleRightIcon;
