import { MAX_PRICE, MIN_PRICE } from "@/libs/constants";
import { useEffect, useState } from "react";
import { IMaskInput } from "react-imask";

type Props = {
  parentMinPrice: number;
  setParentMinPrice: Function;
  parentMaxPrice: number;
  setParentMaxPrice: Function;
  triggerReset: boolean;
};

const PriceSection = ({
  parentMinPrice,
  setParentMinPrice,
  parentMaxPrice,
  setParentMaxPrice,
  triggerReset,
}: Props) => {
  const [minPriceInput, setMinPriceInput] = useState<string>("");
  const [maxPriceInput, setMaxPriceInput] = useState<string>("");

  useEffect(() => {
    setMinPriceInput(`${parentMinPrice}`);
    setMaxPriceInput(`${parentMaxPrice}`);
  }, [parentMinPrice, parentMaxPrice]);

  useEffect(() => {
    if (!triggerReset) return;
    setMinPriceInput(`${MIN_PRICE}`);
    setMaxPriceInput(`${MAX_PRICE}`);
    console.log("triggerReset", triggerReset);
  }, [triggerReset]);

  useEffect(() => {
    setParentMinPrice(Number(minPriceInput));
    setParentMaxPrice(Number(maxPriceInput));
  }, [minPriceInput, maxPriceInput]);

  return (
    <div className="text-base text-[#212529]">
      <div className="text-left text-lg text-[#333] font-semibold pb-4">
        Price
      </div>
      <div className="w-full grid grid-cols-2 gap-x-4 font-semibold pb-4">
        <div className="text-left">
          <label className="form-label inline-block mb-2 text-gray-700">
            Min Price
          </label>
          <div className="flex gap-1 form-control w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded m-0 focus:text-gray-700 focus:border-[#a3b0ca] focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all">
            <span>$</span>
            <IMaskInput
              mask={Number}
              radix="."
              min={MIN_PRICE}
              max={MAX_PRICE}
              thousandsSeparator=","
              placeholder="No min"
              value={minPriceInput}
              className="w-full"
              onFocus={() => {
                if (minPriceInput) {
                  setMinPriceInput("");
                }
              }}
              unmask={true} // Get unmasked value
              onAccept={(value, mask) => {
                setMinPriceInput(value);
              }}
            />
          </div>
        </div>
        <div className="text-left">
          <label className="form-label inline-block mb-2 text-gray-700">
            Max Price
          </label>
          <div className="flex gap-1 form-control w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded m-0 focus:text-gray-700 focus:border-[#a3b0ca] focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all">
            <span>$</span>
            <IMaskInput
              mask={Number}
              radix="."
              thousandsSeparator=","
              min={MIN_PRICE}
              max={MAX_PRICE}
              placeholder="No max"
              value={maxPriceInput}
              className="w-full"
              onFocus={() => {
                if (maxPriceInput) {
                  setMaxPriceInput("");
                }
              }}
              unmask={true}
              onAccept={(value, mask) => {
                setMaxPriceInput(value);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="w-full my-4 border-t-1 border-[#E5E5E5]" />
    </div>
  );
};

export default PriceSection;
