import Image from "next/image";
import Link from "next/link";

type Props = {
  data: any;
};

const JumpLinks = ({ data }: Props) => {
  return (
    <div className="w-full pb-7">
      {data?.Image?.data != null && (
        <div className="w-full flex justify-center">
          <div style={{ width: `${data.Image.data.attributes.width}px` }}>
            <Image
              width={data.Image.data.attributes.width}
              height={data.Image.data.attributes.height}
              src={data.Image.data.attributes.url}
              className="w-full"
              alt={data.Image.data.attributes.alternativeText || ""}
            />
            <div className="w-full flex justify-center">
              <div className="w-[80%] mt-[-80px] vs:mt-[-120px]">
                {data.Links.map((item: any) => {
                  return (
                    <div
                      key={`${item.TargetName}a`}
                      className="bg-[#fff] flex items-center border border-[#d9d9d9] px-4 space-x-2 sm:space-x-6"
                    >
                      {item.Image.data != null ? (
                        <Image
                          width={item.Image.data.attributes.width}
                          height={item.Image.data.attributes.height}
                          src={item.Image.data.attributes.url}
                          alt={item.Image.data.attributes.alternativeText || ""}
                        />
                      ) : (
                        <div className="w-[4px] h-[4px] bg-slate-300 rounded"></div>
                      )}
                      <div
                        key={item.TargetName}
                        className="w-[95%] text-base md:text-xl space-y-4 py-4"
                      >
                        <Link href={`${"#"}${item.Label}`}>
                          <div className="flex justify-between text-slate-500 cursor-pointer">
                            <p className="text-base text-[#000]">
                              {item.TargetName}
                            </p>
                            <Image
                              width={8}
                              height={8}
                              src="/assets/chevron-right-disabled.svg"
                              alt="right icon"
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      {data?.Image?.data == null && (
        <div className="w-5/6 md:w-3/5 mx-auto pb-20 my-6">
          {data.Links.map((item: any) => {
            return (
              <div
                key={`${item.TargetName}a`}
                className="flex items-center space-x-8"
              >
                {item.Image.data != null ? (
                  <Image
                    width={item.Image.data.attributes.width}
                    height={item.Image.data.attributes.height}
                    src={item.Image.data.attributes.url}
                    alt={item.Image.data.attributes.alternativeText || ""}
                  />
                ) : (
                  <div className="w-[4px] h-[4px] bg-slate-300 rounded"></div>
                )}
                <div
                  key={item.TargetName}
                  className="w-[95%] text-base md:text-xl space-y-4"
                >
                  <Link href={`${"#"}${item.Label}`}>
                    <div className="flex justify-between text-slate-500 mt-4 cursor-pointer">
                      <span>{item.TargetName}</span>
                      <span className="pr-4"> {">"} </span>
                    </div>
                  </Link>
                  <hr className="border text-black" />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default JumpLinks;
