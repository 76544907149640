/* eslint-disable @next/next/no-sync-scripts */
import { useEffect, useState } from "react";
import Head from "next/head";
import React from "react";
import UniversalHTag from "@/libs/UniversalHTag";
import { parseTitle, checkTranportCarsPage } from "@/libs/utils";
import {
  publicDomain,
  runBuggyClientId,
  runBuggyScriptUrl,
} from "@/libs/clientConfig";

interface Props {
  data: any;
  runbuggyRef: any;
}

const RunBuggy = ({ data, runbuggyRef }: Props) => {
  const [runBuggyConfig, setRunBuggyConfig] = useState<string>("");
  const [runBuggyScript, setRunBuggyScript] = useState<string>("");

  useEffect(() => {
    setRunBuggyScript(runBuggyScriptUrl());

    const runbuggyConfigValues = {
      clientId: runBuggyClientId(),
      baseUrl: publicDomain(),
    };
    const config = makeWidgetConfig(runbuggyConfigValues);
    setRunBuggyConfig(JSON.stringify(config));
  }, []);

  function camelToSnakeCase(key: string) {
    const result = key.replace(/([A-Z])/g, " $1");
    return result.split(" ").join("_").toLowerCase();
  }

  function makeWidgetConfig(config: any): any {
    const initVariables: any = { ...config };
    const urlParams = new URLSearchParams(window.location.search);

    const copy: any = (name: string, translate = (a: any) => a) => {
      const sName = camelToSnakeCase(name);
      if (urlParams.has(sName))
        initVariables[name] = translate(urlParams.get(sName));
    };

    const copyAsFlag: any = (name: string) => {
      copy(name, (a: any) => (a?.toLowerCase() === "true" ? true : false));
    };

    copy("utm_source");
    copy("utm_medium");
    copy("utm_campaign");

    new Array(
      "fromAddress",
      "toAddress",
      "carCarrierType",
      "contactEmail",
      "pickupContactEmail",
      "pickupContactName",
      "pickupContactPhone",
      "dropoffContactEmail",
      "dropoffContactName",
      "dropoffContactPhone",
      "vin",
      "vehicleMake",
      "vehicleModel",
      "vehicleType",
      "vehicleOdometer"
    ).forEach((name: string) => {
      copy(name);
    });
    copyAsFlag("vehicleIsOperational");
    copyAsFlag("vehicleIsOverSized");
    copy("vehicleYear", (a: any) => parseInt(a) ?? "");
    return initVariables;
  }

  const styles = `.order .theme--light.v-sheet {
    background-color: #fafafa !important;
    color: var(--primary-color) !important;
  }
  .order.light-mode .theme--light.v-sheet {
    background-color: #fafafa !important;
  }
  .order.dark-mode .theme--light.v-sheet {
    background-color: var(--primary-color) !important;
  }
  
  .order ul.errors li {
    list-style: none !important;
  }
  .order .primary {
    background-color: var(--primary-color) !important;
  }
  .order .v-btn {
    letter-spacing: 0 !important;
  }
  .order .v-card {
    padding: 10px !important;
  }
  .order .v-card .light {
    background-color: #fafafa !important;
  }
  
  .order .v-card__text .row > div {
    padding-bottom: 0px !important;
  }
  .order .v-card__text .row > div .component > .forms-radio {
    margin-top: -15px;
  }
  
  /* FORM SCHEDULE COMPONENT */
  .order .component .schedule-container {
    margin-top: 0 !important;
  }
  .order .component .schedule-container .schedule-container__header > div > div {
    line-height: 36px !important;
    font-weight: bold !important;
  }
  .order .component .schedule-container .schedule-container__body {
    padding: 10px !important;
  }
  
  .order .component .schedule-container .schedule-container__body > div > div {
    margin-top: 0 !important;
  }
  .order .component .schedule-container .v-picker--date {
    padding: 0 !important;
  }
  .order .component .schedule-container .schedule-container__body button.mt-5 {
    margin-top: 10px !important;
  }
  .order .component .schedule-container .schedule-container__body .format-window {
    font-weight: bold !important;
    font-size: 15px !important;
  }
  .order
    .component
    .schedule-container
    .schedule-container__body
    > div
    > div
    p.text-uppercase {
    margin-bottom: 0 !important;
  }
  .order
    .component
    .schedule-container
    .schedule-container__body
    > div
    > div
    p
    > button
    > span
    > i {
    color: #ff4747 !important;
    caret-color: #ff4747 !important;
  }
  
  /* FORM TITLE */
  .order h5.title,
  .order h5 {
    color: var(--primary-color) !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 1.2 !important;
    font-family: Raleway !important;
  }
  .order.dark-mode h5.title,
  .order.dark-mode h5 {
    color: #ffffff !important;
  }
  .order h5.title #call_us_at_phone {
    color: var(--secondary-color) !important;
  }
  .order h5.title a,
  .order h5.title a:hover {
    text-decoration: none !important;
  }
  
  /* FORM INPUT FIELDS */
  .order .input-field {
    border: 2px solid #ececec;
    padding: 10px 15px;
    /*margin-bottom: 15px !important;*/
    background-color: #ffffff !important;
  }
  .order .input-label,
  .order .forms-radio .v-label,
  .order .input-field .v-label {
    font-size: 20px !important;
    font-weight: bold !important;
    min-height: 26px !important;
    color: var(--primary-color) !important;
    font-family: Raleway !important;
    padding-bottom: 0 !important;
  }
  .order .input-field .v-label {
    webkit-transform: translateY(-18px) scale(1) !important;
    transform: translateY(-18px) scale(1) !important;
  }
  .order .small-label {
    font-size: 15px !important;
    font-weight: bold !important;
    min-height: 18px !important;
    color: var(--primary-color) !important;
    font-family: Raleway !important;
  }
  .order .small-label span {
    font-weight: normal !important;
  }
  .order .input-field .v-input input,
  .order .input-field .v-input textarea,
  .order .input-field .v-input .v-text-field__prefix {
    margin-top: 15px !important;
  }
  .order .v-input:not(.v-input--is-disabled) input[type="text"],
  .order .v-input:not(.v-input--is-disabled) input[type="email"],
  .order .v-input:not(.v-input--is-disabled) input[type="tel"],
  .order .v-input:not(.v-input--is-disabled) input[type="number"],
  .order .v-input:not(.v-input--is-disabled) textarea {
    font-size: 18px;
  }
  .order .v-input__control > .v-input__slot:before {
    border: none !important;
  }
  .order .input-field .errors {
    margin-bottom: 0 !important;
  }
  
  /* FORM FOOTER */
  .order .card-footer {
    background: transparent !important;
    border: 0 !important;
  }
  
  /* FORM BUTTONS */
  .order .back-button,
  .order .next-button {
    width: 50%;
    padding: 2px;
  }
  .order .full-button {
    width: 100%;
  }
  
  .order .full-button > button,
  .order .next-button > button,
  .order .back-button > button,
  .order .payment-card-footer > button.primary,
  .order .card-footer button.primary {
    background-color: var(--primary-color) !important;
    color: white !important;
    padding: 25px 35px !important;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
  }
  .order button.primary span.v-btn__content {
    color: white !important;
    font-weight: 600;
    font-size: 18px;
  }
  .order .next-button > button.v-btn--disabled,
  .order .back-button > button.v-btn--disabled,
  .order .full-button > button.v-btn--disabled {
    color: #555555 !important;
  }
  
  .order.dark-mode .next-button > button.v-btn--disabled,
  .order.dark-mode .back-button > button.v-btn--disabled,
  .order.dark-mode .full-button > button.v-btn--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
  
  .order.dark-mode .full-button > button,
  .order.dark-mode .next-button > button,
  .order.dark-mode .back-button > button,
  .order.dark-mode .payment-card-footer > button.primary,
  .order.dark-mode .card-footer button.primary {
    background-color: var(--rb1-accent-color) !important;
    color: var(--primary-color) !important;
  }
  .order.dark-mode button span.v-btn__content {
    color: var(--primary-color) !important;
  }
  .order .quote-subtitle {
    font-size: 22px !important;
  }
  .order div.fare {
    font-size: 60px !important;
    font-weight: bold !important;
    margin: 25px 0 !important;
  }
  
  .order .show-quote .component.html .review-inclusions {
    font-size: 18px !important;
    font-weight: 700;
  }
  .order .show-quote .component.html .review,
  .order .show-quote .component.html .review span {
    font-size: 18px !important;
    line-height: 30px;
  }
  .order .show-quote .component.html .review span {
    font-weight: 700;
  }
  .order .show-quote .component.html .review-inclusions h4 {
    font-size: 18px !important;
    color: #ffffff !important;
  }
  
  .component.html .muted,
  .order .show-quote .component.html .muted {
    font-size: 12px !important;
    line-height: initial !important;
  }
  .order .component.html div.terms {
    border-top: 1px solid #afb7c4;
    padding-top: 5px;
    margin-top: 20px;
    text-align: right;
  }
  .order .component.html div.terms span.terms {
    font-size: 15px;
    font-weight: normal;
  }
  `;

  return (
    <>
      <Head>
        <style>{styles}</style>
        <script src="https://code.jquery.com/jquery-3.7.0.slim.min.js"></script>
        {runBuggyConfig?.length > 0 ? (
          <script src={runBuggyScript}></script>
        ) : (
          ""
        )}
      </Head>
      <>
        <div
          className={`relative w-full ${
            !checkTranportCarsPage() && "my-6"
          } h-auto px-[8%] md:px-0 mx-auto bg-[#2F3B55] flex flex-col justify-center items-center`}
          ref={runbuggyRef}
        >
          <div className="w-full">
            <UniversalHTag
              tag={data.TitleStyle}
              classname={`w-full text-2xl ${parseTitle(
                data.TitleStyle
              )} text-center text-black opacity-90 pt-8`}
              content={data.Title}
            />
          </div>
          {data.SubTitle && (
            <div className="w-full">
              <UniversalHTag
                tag={data.SubTitleStyle}
                classname={`w-full text-center text-xl ${parseTitle(
                  data.SubTitleStyle
                )} py-3 md:py-8`}
                content={data.SubTitle}
              />
            </div>
          )}

          <div className="md:max-w-[1140px] my-5">
            {runBuggyConfig !== ""
              ? React.createElement("runbuggy-order" as any, {
                  id: "quote_component",
                  "process-instance-id-query-param": "processInstanceId",
                  "process-definition-key": "RunBuggyOnePlaceOrderB",
                  className: "runbuggy-style-vars ",
                  "init-variables-json": runBuggyConfig,
                })
              : ""}
          </div>
        </div>
      </>
    </>
  );
};

export default RunBuggy;
