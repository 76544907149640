import { useRef } from "react";
import Image from "next/image";
import { vehicles, comingVehicles } from "@/libs/constants";
import { useSearchValue } from "@/contexts/contextSearch";
import useOutsideClick from "@/hooks/useOutsideClick";

type Props = {
  isVehicleModal: Boolean;
  setIsVehicleModal: Function;
  btnRef: any;
};

const VehicleTypeModal = ({
  isVehicleModal,
  setIsVehicleModal,
  btnRef,
}: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { setVehicleType, setCurrent } = useSearchValue();
  const changeVehicleType = (vehicle: string) => {
    setIsVehicleModal(false);
    setVehicleType(vehicle);
  };
  useOutsideClick(modalRef, isVehicleModal, setIsVehicleModal, btnRef);

  return (
    <div
      className="absolute top-[50px] left-[0px] w-[250px] vs:w-[264px] py-2 bg-white text-base text-[#212529] border-2 shadow-2xl rounded-lg z-30"
      ref={modalRef}
    >
      {vehicles.map((item: string, index: number) => {
        return (
          <button
            key={index}
            type="button"
            className="w-[256px] h-[56px] flex items-center space-x-2 text-left px-4 py-2 hover:bg-[#e9ecef]"
            onClick={() => {
              changeVehicleType(item);
              setCurrent(0);
            }}
          >
            <p>{item}</p>
          </button>
        );
      })}
      {comingVehicles.map((item: string, index: number) => {
        return (
          <button
            key={index}
            type="button"
            className="w-[256px] h-[56px] flex items-center space-x-4 text-left text-slate-300 px-4"
            disabled
          >
            <div>
              <p>{item}</p>
            </div>
            <Image
              width={90}
              height={20}
              src="/assets/comingSoon.svg"
              alt="comingSoon"
            />
          </button>
        );
      })}
    </div>
  );
};

export default VehicleTypeModal;
