import { modifyHtml } from "@/libs/utils";

type Props = {
  data: any;
};

const HtmlSection = ({ data }: Props) => {
  return (
    <div className="w-full md:max-w-[1140px] px-[8%] md:px-0 mx-auto">
      <div
        className="w-full"
        dangerouslySetInnerHTML={{
          __html: modifyHtml(data.HTML),
        }}
      ></div>
    </div>
  );
};

export default HtmlSection;
