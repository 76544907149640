import { useEffect, useState } from "react";
import { MIN_MILES, MAX_MILES } from "@/libs/constants";
import { IMaskInput } from "react-imask";

type Props = {
  parentMinMiles: number;
  setParentMinMiles: Function;
  parentMaxMiles: number;
  setParentMaxMiles: Function;
  triggerReset: boolean;
};

const MilesSection = ({
  parentMinMiles,
  setParentMinMiles,
  parentMaxMiles,
  setParentMaxMiles,
  triggerReset,
}: Props) => {
  const [minMilesInput, setMinMilesInput] = useState<string>("");
  const [maxMilesInput, setMaxMilesInput] = useState<string>("");

  useEffect(() => {
    setMinMilesInput(`${parentMinMiles}`);
    setMaxMilesInput(`${parentMaxMiles}`);
  }, [parentMinMiles, parentMaxMiles]);

  useEffect(() => {
    setParentMinMiles(Number(minMilesInput));
    setParentMaxMiles(Number(maxMilesInput));
  }, [minMilesInput, maxMilesInput]);
  useEffect(() => {
    if (!triggerReset) return;
    setMinMilesInput(`${MIN_MILES}`);
    setMaxMilesInput(`${MAX_MILES}`);
  }, [triggerReset]);

  return (
    <div className="text-base text-[#212529]">
      <div className="text-left text-lg text-[#333] font-semibold pb-4">
        Miles
      </div>
      <div className="w-full grid grid-cols-2 gap-x-4 font-semibold pb-4">
        <div className="text-left">
          <label className="form-label inline-block mb-2 text-gray-700">
            Min Miles
          </label>
          <IMaskInput
            mask={Number}
            radix="."
            min={MIN_MILES}
            max={MAX_MILES}
            thousandsSeparator=","
            placeholder="No min"
            value={minMilesInput}
            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded focus:border-[#a3b0ca] focus:text-gray-700 focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all"
            onFocus={() => {
              if (minMilesInput) {
                setMinMilesInput("");
              }
            }}
            unmask={true} // Get unmasked value
            onAccept={(value, mask) => {
              setMinMilesInput(value);
            }}
          />
        </div>
        <div className="text-left">
          <label className="form-label inline-block mb-2 text-gray-700">
            Max Miles
          </label>
          <IMaskInput
            mask={Number}
            radix="."
            min={MIN_MILES}
            max={MAX_MILES}
            thousandsSeparator=","
            placeholder="No max"
            value={maxMilesInput}
            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded focus:border-[#a3b0ca] focus:text-gray-700 focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all"
            onFocus={() => {
              if (maxMilesInput) {
                setMaxMilesInput("");
              }
            }}
            unmask={true}
            onAccept={(value, mask) => {
              setMaxMilesInput(value);
            }}
          />
        </div>
      </div>

      <hr className="w-full my-4 border-t-1 border-[#E5E5E5]" />
    </div>
  );
};

export default MilesSection;
