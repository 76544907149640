import { useState } from "react";
import Image from "next/image";
import { Tabs, TabPanel, Tab, TabList } from "react-tabs";
import { useAuthValue } from "@/contexts/contextAuth";
import "react-tabs/style/react-tabs.css";
import cx from "classnames";

type Props = {
  data: any;
};

const tabClasses = {
  default: "cursor-pointer",
  selected: "font-bold border-b-2 border-[#354360]",
};

const DealNowSection = ({ data }: Props) => {
  const { setIsSignup, setIsDealNowDealStart } = useAuthValue();
  const [isBuyer, setIsBuyer] = useState<boolean>(true);

  const createDealNowLink = () => {
    const customerType = isBuyer ? "BUYER" : "SELLER";
    window?.dataLayer &&
      window.dataLayer.push({
        event: "Deal Now",
        deal_type: customerType,
      });

    window?.fbq &&
      window.fbq("track", "Deal Now", {
        deal_type: customerType,
      });

    setIsSignup(true);
    setIsDealNowDealStart(customerType.toLowerCase());
  };

  return (
    <div className="w-full sm:w-auto flex justify-center ">
      <div className="w-[90%] xs:w-[384px] bg-white border border-[#354360] px-8 xs:pl-[58px] xs:pr-[69px] pt-[44px] pb-[41px] rounded-lg my-8">
        <Tabs>
          <TabList className="w-full flex border-b-2 border-[#354360] text-sm text-[#354360] list-none">
            <Tab
              className={cx(tabClasses.default, "w-1/2 pb-3 text-center")}
              selectedClassName={cx(
                tabClasses.selected,
                "border-t-0 border-r-0 border-l-0 pb-3"
              )}
              onClick={() => setIsBuyer(true)}
            >
              Buyer
            </Tab>
            <Tab
              className={cx(tabClasses.default, "w-1/2 pb-2 text-center")}
              selectedClassName={cx(
                tabClasses.selected,
                "border-t-0 border-r-0 border-l-0 pb-2"
              )}
              onClick={() => setIsBuyer(false)}
            >
              Seller
            </Tab>
          </TabList>
          <div className="border-b border-[#354360]"></div>
          <TabPanel className="pt-8">
            <div className="w-full">
              <div className="w-full h-[100px] flex justify-center mt-8">
                <Image
                  width={83}
                  height={100}
                  src="/assets/buyer-deal.svg"
                  alt="buyer deal"
                  loading="lazy"
                />
              </div>
              <div className="w-full text-base text-center pt-4">
                <p className="text-[#2E3B54] font-bold">{data?.Title}</p>
                <p className="text-[#8A8888] font-normal">{data?.SubTitle}</p>
              </div>
              <button
                className="w-full bg-[#00B3DE] hover:opacity-80 border border-[#00A0C7] text-white text-base font-bold py-2 px-4 rounded cursor-pointer mt-6"
                onClick={() => createDealNowLink()}
              >
                Create a DealNow link
              </button>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="w-full">
              <div className="w-full h-[100px] flex justify-center mt-8">
                <Image
                  width={158}
                  height={98}
                  src="/assets/seller-deal.svg"
                  alt="seller deal"
                  loading="lazy"
                />
              </div>
              <div className="w-full text-base text-center pt-4">
                <p className="text-[#2E3B54] font-bold">{data?.SellerTitle}</p>
                <p className="text-[#8A8888] font-normal">
                  {data?.SellerSubTitle}
                </p>
              </div>
              <button
                className="w-full bg-[#00B3DE] hover:opacity-80 border border-[#00A0C7] text-white text-base font-bold py-2 px-4 rounded cursor-pointer mt-6"
                onClick={() => createDealNowLink()}
              >
                Create a DealNow link
              </button>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default DealNowSection;
