import { checkBlogPage } from "@/libs/utils";

type Props = {
  data: any;
};

const JumpLinkTarget = ({ data }: Props) => {
  return (
    <div
      className={`w-full lg:max-w-[1140px] px-[8%] lg:px-0 mx-auto ${
        !checkBlogPage() ? "my-6" : ""
      }`}
      id={data.Name}
    ></div>
  );
};

export default JumpLinkTarget;
