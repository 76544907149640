import Image from "next/image";
import router from "next/router";

const BuyCarInformational = () => {
  const steps = [
    {
      number: "01",
      step: "Browse",
      background: "#354360",
    },
    {
      number: "02",
      step: "Make Offer",
      background: "#fff",
    },
    {
      number: "03",
      step: "Test Drive",
      background: "#354360",
    },
    {
      number: "04",
      step: "E-sign Bill of Sale",
      background: "#fff",
    },
    {
      number: "05",
      step: "Pay For The Car",
      background: "#354360",
    },
    {
      number: "06",
      step: "Finalize With DMV",
      background: "#fff",
    },
  ];
  return (
    <div className="w-full bg-[#EEEEF5] flex flex-col justify-center items-center py-6">
      <a
        className="flex justify-center pb-6 pt-11 md:w-8/12 "
        onClick={() => router.push("/buy")}
      >
        <Image
          src={"/assets/buyInfoPic.svg"}
          width={1300}
          height={250}
          alt="BuyInfo"
        />
      </a>
      <div className="w-10/12 md:w-8/12  ">
        <div className="text-left">
          <h2 className="text-[34px] font-bold py-4">
            Buying your Car on PrivateAuto
          </h2>
          <div className="text-[20px] font-normal">
            We streamline the process of buying a used car from a private
            seller. Here are the steps involved.
          </div>
        </div>

        <div className="flex flex-col md:flex-row py-5">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`bg-[${step.background}] md:w-full sm:w-full h-[167px] rounded-xl mb-4 md:mr-4 md:mb-0`}
            >
              <div className="p-4">
                <div className="text-[#4DCBAB] md:text-[24px] text-[32px] font-bold pb-4">
                  {step.number}
                </div>
                <div
                  className={`md:text-[18px] text-[24px] font-bold text-[${
                    step.background === "#354360" ? "#fff" : ""
                  }]`}
                >
                  {step.step}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BuyCarInformational;
