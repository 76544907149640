import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import useApi from "./useApi";

export const useQuerySellInquiry = () => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { inquiry: inquiryId } = router.query;
  const { makeRequest } = useApi();
  return useQuery<any>({
    queryKey: ["sell-inquiry", inquiryId],
    queryFn: () =>
      makeRequest({
        method: "GET",
        url: `/api/vehicles/sell-inquiry/${inquiryId}`,
      }),
    enabled: Boolean(inquiryId),
    initialData: () => {
      if (!inquiryId) return undefined;
      return queryClient.getQueryData(["sell-inquiry", String(inquiryId)]);
    },
  });
};
