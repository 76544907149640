import Image from "next/image";
import { parseWidth } from "@/libs/utils";

type Props = {
  data: any;
};

const WatermarkBlock = ({ data }: Props) => {
  return (
    <div className="w-full bg-[linear-gradient(180deg,#53cce8,#53d9e8)]">
      <div className={`${parseWidth(data.Width)} px-8 md:px-[13%] my-6`}>
        <div className="relative w-full">
          {data?.Logo?.data && (
            <div className="absolute top-[25px] left-[0px] opacity-100">
              <Image
                width={data.Logo.data.attributes.width}
                height={data.Logo.data.attributes.height}
                src={data.Logo.data.attributes.url}
                alt="Logo"
              />
            </div>
          )}
          <p className="text-left text-[#2c2641] text-2xl md:text-[40px] font-normal md:leading-[48px] pl-4 md:px-8 py-[100px] z-10">
            {data.Content}
          </p>
          <p className="absolute right-0 bottom-[-18px] md:bottom-[-20px] text-right text-white text-4xl md:text-[94px] md:leading-[94px] font-bold opacity-60">
            {data.Watermark}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WatermarkBlock;
