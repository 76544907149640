import Image from "next/image";
import { useRef, useState } from "react";

type Props = {
  data: any;
};

const InformationalBlock = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoBtnShow, setIsVideoBtnShow] = useState<boolean>(true);

  const playVideo = () => {
    if (videoRef.current) {
      // @ts-ignore
      videoRef.current.play();
      setIsVideoBtnShow(false);
    }
  };
  return (
    <>
      <div className="bg-[#F9F9F9] flex flex-wrap justify-center items-center  min-h-[443px] w-full">
        <div className="px-4 py-8 ">
          <div className="text-[24px] font-[700]">
            Everything you need all in one app.{" "}
          </div>
          <div className="pt-2">
            <div className="flex pl-3 pb-2">
              <Image
                src={"/assets/bankIcon.svg"}
                alt="BankIcon"
                width={30}
                height={33}
              />
              <div className="py-2 pl-3 text-[20px] font-[500]">
                Guaranteed funds
              </div>
            </div>
            <div className="flex pl-3 pb-2">
              <Image
                src={"/assets/moneyMove.svg"}
                alt="BankIcon"
                width={30}
                height={33}
              />
              <div className="py-2 pl-3 text-[20px] font-[500]">
                Instant payment P2P
              </div>
            </div>
            <div className="flex pl-3 pb-2">
              <Image
                src={"/assets/identityIcon.svg"}
                alt="BankIcon"
                width={30}
                height={33}
              />
              <div className="py-2 pl-3 text-[20px] font-[500]">
                Identity verification
              </div>
            </div>
            <div className="flex pl-3 pb-2">
              <Image
                src={"/assets/eSigniture.svg"}
                alt="BankIcon"
                width={30}
                height={33}
              />
              <div className="py-2 pl-3 text-[20px] font-[500]">
                E-sign the bill of sale
              </div>
            </div>
            <div className="flex pl-3 pb-2">
              <Image
                src={"/assets/clipboard.svg"}
                alt="BankIcon"
                width={30}
                height={33}
              />
              <div className="py-2 pl-3 text-[20px] font-[500]">
                Vehicle history report
              </div>
            </div>
          </div>
        </div>

        <div className="relative w-[290px] vs:w-[400px] md:w-[480px] lg:w-[645px] h-[163.6px] vs:h-[225.7px] md:h-[271px] lg:h-[364px] z-10 mb-4">
          <video
            ref={videoRef}
            preload="auto"
            width={645}
            height={364}
            className="w-full h-full"
            controls={true}
            poster="https://strapi-cms-media-live.s3.us-east-2.amazonaws.com/video_Cover_Landing_267ed9eb1e.png"
          >
            <source
              src={
                "https://d3bey6k4gof52l.cloudfront.net/first-ever-privateauto-transaction-short.mp4"
              }
            />
          </video>
          {isVideoBtnShow && (
            <div className="absolute left-0 top-0 w-full h-full flex justify-center items-center">
              <div>
                <Image
                  width={88}
                  height={88}
                  src="/assets/video-play.svg"
                  className="cursor-pointer w-[45px] vs:w-[88px] mx-4 vs:mx-0"
                  onClick={() => playVideo()}
                  alt="video play"
                />
                <div className="w-[80px] vs:w-[102px] h-[32px] bg-[#2c2641] text-center text-sm vs:text-base font-semibold text-white py-1 my-2">
                  Play Video
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default InformationalBlock;
