import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Faq } from "@/interfaces/MoreFilter";
import {
  checkHomePage,
  parseTitle,
  parseContent,
  checkDealNowPage,
  checkSellPage,
} from "@/libs/utils";
import UniversalHTag from "@/libs/UniversalHTag";

type Props = {
  data: any;
};
const FaqCollection = ({ data }: Props) => {
  const [collectionData, setCollectionData] = useState<any>(data);
  const svgVariants = {
    default: { rotate: 0 },
    rotated: { rotate: 45 },
  };
  const updateContentStatus = (id: number) => {
    setCollectionData((prevState: any) => {
      const updatedFAQs = prevState.FAQs.map((item: Faq) => {
        if (item.id === id) {
          return {
            ...item,
            Default: item.Default === "Open" ? "Closed" : "Open",
          };
        } else {
          return {
            ...item,
            Default: item.Default === "Closed",
          };
        }
      });
      return {
        ...prevState,
        FAQs: updatedFAQs,
      };
    });
  };

  return (
    <div
      className={`w-full ${
        checkHomePage()
          ? "lg:max-w-[988px]"
          : checkDealNowPage()
          ? "lg:max-w-[936px]"
          : "lg:max-w-[1140px]"
      } px-[8%] lg:px-0 mx-auto my-6`}
    >
      {collectionData.Title != null && (
        <div className="py-4">
          <UniversalHTag
            tag={collectionData.TitleStyle}
            classname={`${!checkDealNowPage() && "text-center"} ${
              checkDealNowPage()
                ? "text-3xl sm:text-[40px] font-bold"
                : parseTitle(collectionData.TitleStyle, collectionData.Title)
            }`}
            content={collectionData.Title}
          />
        </div>
      )}
      <hr className="hr-thin" />
      {collectionData.FAQs.map((item: Faq, index: number) => {
        return (
          <div
            key={index}
            className={`overflow-hidden ${
              checkSellPage() &&
              data.Title == null &&
              "border border-[#E0E0E0] border-t-0"
            }`}
          >
            <div
              className="flex justify-between items-center cursor-pointer px-6 mt-[20px]"
              onClick={() => updateContentStatus(item.id)}
            >
              <h3 className="w-[95%] font-bold text-lg cursor-pointer">
                {item.Title}
              </h3>
              <motion.svg
                fill="#00b3de"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                width="12px"
                height="12px"
                viewBox="0 0 92.13 92.13"
                animate={item.Default == "Open" ? "default" : "rotated"}
                variants={svgVariants}
                transition={{ duration: 0.7 }}
              >
                <g id="SVGRepo_bgCarrier"></g>
                <g id="SVGRepo_tracerCarrier"></g>
                <g id="SVGRepo_iconCarrier">
                  <g>
                    <g>
                      <path d="M2.141,89.13c1.425,1.429,3.299,2.142,5.167,2.142c1.869,0,3.742-0.713,5.167-2.142l33.591-33.592L79.657,89.13 c1.426,1.429,3.299,2.142,5.167,2.142c1.867,0,3.74-0.713,5.167-2.142c2.854-2.854,2.854-7.48,0-10.334L56.398,45.205 l31.869-31.869c2.855-2.853,2.855-7.481,0-10.334c-2.853-2.855-7.479-2.855-10.334,0L46.065,34.87L14.198,3.001 c-2.854-2.855-7.481-2.855-10.333,0c-2.855,2.853-2.855,7.481,0,10.334l31.868,31.869L2.143,78.795 C-0.714,81.648-0.714,86.274,2.141,89.13z"></path>
                    </g>
                  </g>
                </g>
              </motion.svg>
            </div>
            <AnimatePresence>
              {item.Default == "Open" && (
                <motion.div
                  initial={{ height: 0 }}
                  animate={{ height: "fit-content" }}
                  exit={{ height: 0 }}
                  transition={{ duration: 0.7 }}
                >
                  <div
                    className="font-medium text-[#828282] text-lg pt-6 px-6 mt-[20px]"
                    dangerouslySetInnerHTML={{
                      __html: parseContent(item.Content),
                    }}
                  ></div>
                </motion.div>
              )}
            </AnimatePresence>
            <hr className="hr-thin mt-[20px]" />
          </div>
        );
      })}
    </div>
  );
};

export default FaqCollection;
