import { useEffect, useRef } from "react";
import Image from "next/image";
import { STATE_LIST } from "@/libs/constants";
import useOutsideClick from "@/hooks/useOutsideClick";
import { checkSellPage } from "@/libs/utils";

type Props = {
  isStateModalOpened: Boolean;
  setIsStateModalOpened: Function;
  setState: Function;
  btnRef: any;
};

const StateListModal = ({
  isStateModalOpened,
  setIsStateModalOpened,
  setState,
  btnRef,
}: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(modalRef, isStateModalOpened, setIsStateModalOpened, btnRef);

  return (
    <div
      className={`absolute top-[46px] w-[140px] h-[300px] overflow-y-scroll py-2 bg-white text-base text-[#16181b] border border-[#000] border-opacity-60 rounded z-30`}
      ref={modalRef}
    >
      {STATE_LIST.sort().map((item: string, index: number) => {
        return (
          <button
            key={index}
            type="button"
            className="w-full text-left px-4 py-1 hover:bg-[#e9ecef]"
            onClick={() => {
              setState(item);
              setIsStateModalOpened(false);
            }}
          >
            {item}
          </button>
        );
      })}
    </div>
  );
};

export default StateListModal;
