import { useEffect, useRef } from "react";
import Autocomplete from "react-google-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { useSearchValue } from "@/contexts/contextSearch";
import useOutsideClick from "@/hooks/useOutsideClick";

type Props = {
  setIsLocationModal: Function;
  isLocationModal: Boolean;
  googleAutocompleteApiKey: string;
};

const LocationModal = ({
  setIsLocationModal,
  isLocationModal,
  googleAutocompleteApiKey,
}: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const {
    location,
    setLocation,
    setLat,
    setLng,
    radius,
    setRadius,
    selectedRadius,
    setSelectedRadius,
    selectedPlace,
    setSelectedPlace,
    selectedState,
    setSelectedState,
    selectedLat,
    setSelectedLat,
    selectedLng,
    setSelectedLng,
    setState,
    isOnlyState,
    setIsOnlyState,
    setCurrent,
  } = useSearchValue();

  const apply = () => {
    setIsLocationModal(false);
    if (isOnlyState) {
      setCurrent(0);
      setLocation(selectedState + ", USA");
      setState(selectedState);
    } else {
      setCurrent(0);
      setState("");
      setLat(selectedLat);
      setLng(selectedLng);
      setRadius(selectedRadius);
      setLocation(selectedPlace);
    }
  };

  const clear = () => {
    setIsLocationModal(false);
    setSelectedRadius(50);
    setSelectedPlace("");
    setSelectedState("");
    setSelectedLat(0);
    setSelectedLng(0);
    setLocation("");
    setLat(0);
    setLng(0);
    setRadius(50);
    setState("");
    setIsOnlyState(false);
    setCurrent(0);
  };

  useOutsideClick(modalRef, isLocationModal, setIsLocationModal);

  useEffect(() => {
    setSelectedRadius(radius);
  }, []);

  return (
    <div className="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-60 justify-center items-center overflow-y-hidden inset-0 z-50 outline-none focus:outline-none">
      <div
        className="w-full xs:w-[340px] mx-auto opacity-100 bg-white text-[#212529] border-1 border-2-gray-400 shadow-md rounded-lg py-4 mt-40"
        ref={modalRef}
      >
        <div className="w-full px-6 pt-3 pb-8 space-y-4">
          <div className="text-left">
            <label className="form-label inline-block text-lg font-medium text-[#333]">
              Location
            </label>
            <div>
              <Autocomplete
                className="form-control block w-full px-3 py-2 text-base font-medium text-[#495057] bg-white bg-clip-padding border border-solid border-grey rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-700 focus:outline-none"
                apiKey={googleAutocompleteApiKey}
                defaultValue={location}
                onPlaceSelected={(place) => {
                  const address = place.formatted_address || "";
                  if (place.address_components != undefined) {
                    if (place.address_components.length < 3) {
                      setSelectedState(place.address_components[0].long_name);
                      setIsOnlyState(true);
                    } else {
                      setIsOnlyState(false);
                    }
                  }
                  setSelectedPlace(address);
                  geocodeByAddress(address)
                    .then((results) => getLatLng(results[0]))
                    .then(({ lat, lng }) => {
                      setSelectedLat(lat);
                      setSelectedLng(lng);
                    });
                }}
                options={{
                  types: ["(regions)"],
                  componentRestrictions: { country: "us" },
                }}
              />
            </div>
          </div>
          <div className="text-left">
            <label className="form-label inline-block text-lg font-medium text-[#333]">
              Mile radius
            </label>
            <select
              className={`custom-select form-control block w-full px-3 py-2 text-base font-medium text-[#063829] ${
                isOnlyState ? "bg-[#eff2f4]" : "bg-white"
              } bg-clip-padding border border-solid border-grey rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-700 focus:outline-none cursor-pointer`}
              value={selectedRadius}
              onChange={(e: any) => setSelectedRadius(e.target.value)}
              disabled={isOnlyState}
            >
              <option value={10}>10 miles</option>
              <option value={25}>25 miles</option>
              <option value={50}>50 miles</option>
              <option value={100}>100 miles</option>
              <option value={200}>200+ miles</option>
            </select>
          </div>
        </div>
        <hr className="w-full" />
        <div className="flex justify-between px-4 pt-6">
          <button
            className="bg-[#f7f9fc] hover:opacity-80 text-base font-medium py-2 px-3 hover:border-transparent rounded"
            onClick={() => clear()}
          >
            Clear
          </button>
          <button
            className="bg-[#00b3de] hover:opacity-80 text-white text-sm font-bold py-2 px-3 rounded cursor-pointer"
            onClick={() => apply()}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default LocationModal;
