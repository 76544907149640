import Image from "next/image";
import Link from "next/link";
import CircleIcon from "@/components/Icon/CircleIcon";
import { resourcesArr, fantasticArticlesArr } from "@/libs/constants";

const PageNotFound = () => {
  return (
    <div className="w-full bg-[#eff1f6]">
      <div className="max-w-[1140px] mx-auto text-[#212529]">
        <div className="grid sm:grid-cols-2 items-center py-3">
          <div className="text-center sm:text-left px-12 opacity-90">
            <p className="text-[40px] font-bold py-6">End of the Road</p>
            <p className="text-xl">
              We must have sent you down a dead-end street. Let&apos;s get you
              out of here.
            </p>
          </div>
          <div className="flex justify-center">
            <Image
              width={446}
              height={339}
              src="/assets/pageNotFound.svg"
              className="w-[50%] sm:w-auto "
              alt="pageNotFound"
            />
          </div>
        </div>
        <div className="w-full px-12">
          <p className="text-3xl font-bold text-center sm:text-left opacity-95">
            You might like these helpful resources.
          </p>
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 text-lg font-bold">
            {resourcesArr.map((item: any, index: number) => {
              return item.link.includes("/blog") ? (
                <a
                  key={index}
                  className="bg-white text-center border border-[#000] hover:underline py-4 rounded-xl mt-4 mr-4"
                  href={"https://privateauto.com" + item.link}
                >
                  {item.name}
                </a>
              ) : (
                <Link
                  key={index}
                  className="bg-white text-center border border-[#000] hover:underline py-4 rounded-xl mt-4 mr-4"
                  href={item.link.slice(1)}
                >
                  {item.name}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="w-full pt-10">
          <p className="text-3xl font-bold text-center sm:text-left opacity-95 px-12">
            Check out these fantastic articles
          </p>
          <div className="w-full grid grid-rows-7 grid-cols-2 px-9 pt-10 pb-2">
            {fantasticArticlesArr.map((item: any, index: number) => {
              return (
                <Link
                  key={index}
                  className="flex items-center space-x-2 underline py-1"
                  href={item.link.slice(1)}
                >
                  <CircleIcon />
                  <div>{item.article}</div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
