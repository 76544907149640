import Image from "next/image";
import { parseTextColor, parseTitle } from "@/libs/utils";
import UniversalHTag from "@/libs/UniversalHTag";

type Props = {
  data: any;
};

const HeaderWithOverlap = ({ data }: Props) => {
  return (
    <div className={`relative w-full ${parseTextColor(data.Color)}`}>
      {data?.Image?.data?.attributes && (
        <div className="relative max-w-full">
          <Image
            width={data.Image.data.attributes.width}
            height={data.Image.data.attributes.height}
            className="object-cover w-full h-[457px]"
            src={data.Image.data.attributes.url}
            alt="Overlap"
          />
          <div className="absolute top-0 left-0 w-full h-full bg-grey"></div>
        </div>
      )}
      <div className="absolute top-0 left-0 w-full">
        <UniversalHTag
          tag={data.TitleStyle}
          classname={`w-full text-center text-2xl ${parseTitle(
            data.TitleStyle
          )} pt-16 md:pt-20`}
          content={data.Title}
        />
        <UniversalHTag
          tag={data.SubTitleStyle}
          classname={`w-full text-center text-xl ${parseTitle(
            data.SubTitleStyle
          )} py-3 md:py-8`}
          content={data.SubTitle}
        />
        <p className={`w-full text-center text-lg md:text-xl py-4 md:py-10`}>
          {data.Content}
        </p>
      </div>
    </div>
  );
};

export default HeaderWithOverlap;
