import { useRef } from "react";
import Image from "next/image";
import { vehiclesOnSellEntry, comingVehicles } from "@/libs/constants";
import useOutsideClick from "@/hooks/useOutsideClick";
import { checkHomePage, checkSellPage } from "@/libs/utils";

type Props = {
  isVehicleModalOnSellEntry: Boolean;
  setIsVehicleModalOnSellEntry: Function;
  setVehicleType: Function;
  btnRef: any;
  designType?: any;
};

const VehicleTypeModalOnSellSentry = ({
  isVehicleModalOnSellEntry,
  setIsVehicleModalOnSellEntry,
  setVehicleType,
  btnRef,
  designType = null,
}: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(
    modalRef,
    isVehicleModalOnSellEntry,
    setIsVehicleModalOnSellEntry,
    btnRef
  );

  return (
    <div
      className={`absolute top-[46px] ${
        checkHomePage() || checkSellPage() || designType == "Design 2"
          ? "left-0 w-[180px]"
          : "left-1/12 sm:left-[20%] w-11/12 sm:w-3/5"
      } py-2 bg-white text-base text-[#16181b] border border-[#000] border-opacity-60 rounded z-30`}
      ref={modalRef}
    >
      {vehiclesOnSellEntry.map((item: string, index: number) => {
        return (
          <button
            key={index}
            type="button"
            className="w-full text-left px-4 py-1 hover:bg-[#e9ecef]"
            onClick={() => {
              setVehicleType(item);
              setIsVehicleModalOnSellEntry(false);
            }}
          >
            {item}
          </button>
        );
      })}
      {comingVehicles.map((item: string, index: number) => {
        return (
          <button
            key={index}
            type="button"
            className="w-[256px] flex items-center text-left text-slate-300 px-4 py-1"
            disabled
          >
            {item}&nbsp;&nbsp;&nbsp;&nbsp;
            <Image
              width={90}
              height={20}
              src="/assets/comingSoon.svg"
              alt="comingSoon"
            />
          </button>
        );
      })}
    </div>
  );
};

export default VehicleTypeModalOnSellSentry;
