import { checkFinancingCarsPage, setScrollHidden } from "@/libs/utils";
import { useAuthValue } from "@/contexts/contextAuth";

type Props = {
  data: any;
  previousData: any;
};

const ColumnsEnd = ({ data, previousData }: Props) => {
  const { setIsSignup } = useAuthValue();

  return (
    <div className="w-full">
      {data.Border != "none" && <hr className="w-full" />}
      {checkFinancingCarsPage() &&
        previousData?.Content ==
          "**3. Easy transactions.** Send Funds instantly to complete the deal in the parking lot" && (
          <div className="w-full flex justify-center pt-8">
            <button
              className="bg-[#00b3de] text-white text-base font-normal py-2 px-1 xs:px-3 rounded cursor-pointer"
              onClick={() => {
                setIsSignup(true);
                setScrollHidden();
              }}
            >
              Get Started
            </button>
          </div>
        )}
    </div>
  );
};

export default ColumnsEnd;
