const CircleLeftIcon = (props: any) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="18"
        cy="18"
        r="17"
        transform="rotate(-180 18 18)"
        fill="white"
        stroke={props.color}
        strokeWidth="2"
      />
      <path
        d="M21 12L15 18L21 24"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CircleLeftIcon;
