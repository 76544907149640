import { useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { checkTranportCarsPage } from "@/libs/utils";

type Props = {
  data: any;
};

const VideoPlayer = ({ data }: Props) => {
  const iframeRef = useRef(null);
  const router = useRouter();

  const checkFinancingCarsPage = () => {
    const asPath = router.asPath;
    return asPath.includes("financing/cars");
  };

  const convertVideoURL = (url: string) => {
    let converted_url = "https://www.youtube.com/embed/";
    if (url.includes("youtu.be")) {
      converted_url += url.replace("https://youtu.be/", "");
    } else {
      converted_url = url;
    }
    return converted_url;
  };

  useEffect(() => {
    const updateVideoSize = () => {
      if (iframeRef.current) {
        //@ts-ignore
        const containerWidth = iframeRef.current.offsetWidth;
        const videoWidth = containerWidth;
        const videoHeight = (videoWidth * 370) / 645;
        //@ts-ignore
        iframeRef.current.style.width = `${videoWidth}px`;
        //@ts-ignore
        iframeRef.current.style.height = `${videoHeight}px`;
      }
    };
    if (checkFinancingCarsPage()) {
      updateVideoSize();
    }

    window.addEventListener("resize", updateVideoSize);

    return () => {
      window.removeEventListener("resize", updateVideoSize);
    };
  }, [router]);

  return (
    <div className="w-full flex justify-center px-4 my-6">
      <iframe
        ref={iframeRef}
        width={
          data.Poster.data != null && data.Poster.data.attributes != null
            ? data.Poster.data.attributes.width
            : 645
        }
        height={
          data.Poster.data != null && data.Poster.data.attributes != null
            ? data.Poster.data.attributes.height
            : 370
        }
        className={`${
          checkFinancingCarsPage() || checkTranportCarsPage()
            ? "w-full lg:max-w-[1140px] lg:h-[500px]"
            : "w-[300px] vs:w-[500px] sm:w-[645px] h-[172px] vs:h-[286.8px] sm:h-[370px]"
        }`}
        src={convertVideoURL(data.URL)}
        title={data.Title}
      ></iframe>
    </div>
  );
};

export default VideoPlayer;
