import Link from "next/link";

type Props = {
  data: any;
};

const JumpButton = ({ data }: Props) => {
  return (
    <div className="w-full flex justify-center py-3">
      <Link href={`${"#"}${data.TargetName}`}>
        <button className="bg-[#00b3de] hover:opacity-80 text-base text-white font-semibold py-2 px-4 border border-[#00a0c7] border-solid rounded">
          {data.Label}
        </button>
      </Link>
    </div>
  );
};

export default JumpButton;
