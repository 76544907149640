import ContentBlock from "@/components/ContentBlock";
import {
  parseWidth,
  parseTitle,
  parseBgColor,
  checkColumnsHairline,
  checkTranportCarsPage,
  checkInsurancePage,
} from "@/libs/utils";
import UniversalHTag from "@/libs/UniversalHTag";
import IconBar from "@/components/IconBar";

type Props = {
  data: any;
  index: number;
};

const ColumnsStart = ({ data, index }: Props) => {
  let endIndex = data.attributes.Content.length;
  const line_style = data.attributes.Content[index]?.Lines;

  for (let i = index; i < data.attributes.Content.length; i++) {
    if (data.attributes.Content[i].__component == "page-elements.columns-end") {
      endIndex = i;
      break;
    }
  }
  return (
    <div
      className={`${parseBgColor(data.attributes.Content[index].Color)} ${
        checkTranportCarsPage() && "pb-10"
      }${checkInsurancePage() && " py-5 pb-6"}`}
    >
      <div className="w-full lg:max-w-[1140px] px-[8%] lg:px-0 mx-auto ">
        {data.attributes.Content[index].Title != null && (
          <UniversalHTag
            tag={data.attributes.Content[index].TitleStyle}
            classname={`${parseTitle(
              data.attributes.Content[index].TitleStyle
            )} text-center py-3`}
            content={data.attributes.Content[index].Title}
          />
        )}

        <div className="w-full flex flex-wrap">
          {data.attributes.Content.map((item: any, i: number) => {
            if (
              i > index &&
              i <= endIndex &&
              (item.__component != "page-elements.column" ||
                item.__component != "page-elements.columns-start" ||
                item.__component != "page-elements.columns-end")
            ) {
              if (
                data.attributes.Content[i - 1].__component ==
                "page-elements.column"
              ) {
                return (
                  <div
                    className={parseWidth(
                      data.attributes.Content[i - 1].ColumnWidth
                    )}
                    key={i}
                  >
                    {item.__component == "page-elements.image-text" ? (
                      checkColumnsHairline(data.attributes.Content, i) &&
                      line_style == "hairline" ? (
                        <div className="flex">
                          <ContentBlock data={item} isArticle={true} />
                          <div className="hidden td:block w-[2px] bg-[#c3c3c3] z-40"></div>
                        </div>
                      ) : (
                        <ContentBlock data={item} isArticle={true} />
                      )
                    ) : item.__component == "page-elements.icon-bar" ? (
                      checkColumnsHairline(data.attributes.Content.length, i) &&
                      line_style == "hairline" ? (
                        <div className="flex">
                          <IconBar
                            color={data.attributes.Content[index].Color}
                            data={item}
                          />
                          <div className="hidden td:block w-[2px] bg-[#c3c3c3] z-40"></div>
                        </div>
                      ) : (
                        <IconBar
                          color={data.attributes.Content[index].Color}
                          data={item}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                );
              } else {
                return item.__component == "page-elements.image-text" ? (
                  <ContentBlock key={i} data={item} />
                ) : item.__component == "page-elements.icon-bar" ? (
                  <IconBar key={i} data={item} />
                ) : (
                  ""
                );
              }
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default ColumnsStart;
