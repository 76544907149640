import Script from "next/script";
import React from "react";

const GoogleReviewsScript = () => {
  return (
    <>
      <Script
        src="https://static.elfsight.com/platform/platform.js"
        strategy="afterInteractive"
        data-use-service-core=""
      />
      <div className="elfsight-app-325be2a7-a5f6-43c0-97be-92511709d698"></div>
    </>
  );
};

export default GoogleReviewsScript;
