import { parseBgColor, parseTitle, getTableHeaderWidth } from "@/libs/utils";
import getCellData from "@/libs/getCellData";
import UniversalHTag from "@/libs/UniversalHTag";
import { checkIsHeader } from "@/libs/utils";

type Props = {
  data: any;
};

const Table = ({ data }: Props) => {
  let colsArr: Array<Array<any>> = [[]];
  let headArr: Array<any> = [];
  let colIndex = -1;
  data.Table.data.attributes.Elements.forEach((item: any) => {
    if (item.__component == "sub-elements.new-row") {
      colIndex++;
      if (!colsArr[colIndex]) {
        colsArr[colIndex] = [];
      }
    }
    colsArr[colIndex].push(item);
  });

  for (let i = 0; i < colsArr.length; i++) {
    if (colsArr[i][1]?.__component == "sub-elements.cell-header") {
      headArr = colsArr[i];
      colsArr.splice(i, 1);
      break;
    }
  }

  return (
    <div className="w-full lg:max-w-[1140px] px-0 vs:px-[8%] lg:px-0 mx-auto pt-6">
      <div className="w-full px-8 vs:px-0">
        <UniversalHTag
          tag={data.TitleStyle}
          classname={`${parseTitle(data.TitleStyle)} text-[#333]`}
          content={data.Title}
        />
        <UniversalHTag
          tag={data.SubTitleStyle}
          classname={`${parseTitle(data.SubTitleStyle)} text-[#4f4f4f]`}
          content={data.SubTitle}
        />
      </div>
      <table className="w-full py-6">
        <thead>
          <tr className="w-full">
            {headArr.map((item: any, index: number) => {
              return (
                index > 0 && (
                  <td
                    key={index}
                    className={`${getTableHeaderWidth(
                      data.Table.data.attributes?.Column[index - 1]
                    )}, ${index > 1 && "text-center"} px-1 vs:px-4 py-4`}
                  >
                    {getCellData(item, true)}
                  </td>
                )
              );
            })}
          </tr>
        </thead>
        <tbody>
          {colsArr.map((colData: any, i: number) => {
            return (
              <tr key={i} className={parseBgColor(colData[0].StyleName)}>
                {colData.map((item: any, index: number) => {
                  return (
                    index > 0 && (
                      <td
                        key={index}
                        className={`${index > 1 && "text-center"} px-4 py-4`}
                      >
                        {getCellData(item, checkIsHeader(colData))}
                      </td>
                    )
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
