import Head from "next/head";
import Link from "next/link";
import Snippets from "@/components/layout/Snippets";
import {
  parseCTAColor,
  parseContentBlockContent,
  parseTextColor,
} from "@/libs/utils";

type Props = {
  snippets?: any[];
  banner?: any;
};

const Header = ({ snippets, banner }: Props) => {
  return (
    <>
      <Snippets snippets={snippets ?? []} />
      <div className="w-full">
        <Head>
          <link rel="icon" href="/favicon.ico" key="favicon" />
        </Head>
        {banner?.Live && (
          <header
            style={{ backgroundColor: banner.BackgroundColor }}
            className="w-full h-[52px] p-2 flex justify-center items-center text-white space-x-2 md:space-x-3"
          >
            {banner.AddButton && banner.ButtonPosition === "Left" && (
              <Link href={banner.ButtonLinkUrl}>
                <button
                  className={` ${parseCTAColor(
                    banner.ButtonColor
                  )}w-[83px] sm:w-[92px] bg-white hover:bg-slate-100 text-[#063829] text-xs sm:text-sm font-medium px-2 py-1 rounded cursor-pointer`}
                >
                  {banner.ButtonText || "Learn More"}
                </button>
              </Link>
            )}
            <div
              className={`text-center md:text-left text-sm md:text-lg font-normal px-2 xs:px-4 ${parseTextColor(
                banner.BannerTextColor
              )}`}
              dangerouslySetInnerHTML={{
                __html: parseContentBlockContent(banner.BannerText),
              }}
            ></div>
            {banner.AddButton && banner.ButtonPosition === "Right" && (
              <Link href={banner.ButtonLinkUrl}>
                <button
                  className={` ${parseCTAColor(
                    banner.ButtonColor
                  )}w-[83px] sm:w-[92px] bg-white hover:bg-slate-100 text-[#063829] text-xs sm:text-sm font-medium px-2 py-1 rounded cursor-pointer`}
                >
                  {banner.ButtonText || "Learn More"}
                </button>
              </Link>
            )}
          </header>
        )}
      </div>
    </>
  );
};

export default Header;
