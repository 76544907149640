import { useEffect, useRef, createElement, Fragment } from "react";
import { createRoot } from "react-dom/client";
import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";
import algoliasearch from "algoliasearch/lite";

import type { AutocompleteComponents } from "@algolia/autocomplete-js";
import type { Hit } from "@algolia/client-search";
import type { Root } from "react-dom/client";

import "@algolia/autocomplete-theme-classic";
import styles from "./search-styles.module.css";
import { parseAlignment, parseBgColor, parseTitleColor } from "@/libs/utils";
import UniversalHTag from "@/libs/UniversalHTag";
import { alogoliaAppId, alogoliaPublicKey } from "@/libs/clientConfig";

type ProductHit = Hit<{
  title: string;
  slug: string;
}>;
type Props = {
  data: any;
};
export function BlogSearch(props: Props) {
  const { data } = props;
  const searchClient = algoliasearch(alogoliaAppId, alogoliaPublicKey);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const panelRootRef = useRef<Root | null>(null);
  const rootRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete<ProductHit>({
      container: containerRef.current,
      placeholder: "Start Search",
      insights: true,
      detachedMediaQuery: "none",
      getSources({ query }) {
        return [
          {
            sourceId: "products",
            getItems() {
              return getAlgoliaResults<ProductHit>({
                searchClient,
                queries: [
                  {
                    indexName: "pa_blogs",
                    query,
                  },
                ],
              });
            },
            templates: {
              item({ item, components }) {
                return <ProductItem hit={item} components={components} />;
              },
              noResults() {
                return "No articles matching.";
              },
            },
          },
        ];
      },
      renderer: { createElement, Fragment, render: () => {} },
      render({ children }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;

          panelRootRef.current?.unmount();
          panelRootRef.current = createRoot(root);
        }

        panelRootRef.current.render(children);
      },
    });

    return () => {
      search.destroy();
    };
  }, []);

  return (
    <div
      className={`py-[90px] ${parseBgColor(
        data.Color
      )} px-6 space-y-10 flex flex-col items-center`}
    >
      {data?.Title != null && (
        <UniversalHTag
          tag={data.TitleStyle}
          classname={`${parseTitleColor(data.Color)} ${parseAlignment(
            data.TitleAlignment,
            data.Title
          )} text-[28px] sm:text-[40px] font-bold`}
          content={
            data.Title != undefined && data.Title != null ? data.Title : ""
          }
        />
      )}
      <div className="w-full sm:w-[472px]">
        <div
          // style={{
          //   // eslint-disable-next-line
          //   "--aa-input-border-color-rgb": "rgb(23, 162, 184)",
          // }}
          className={`w-full ${styles.search}`}
          ref={containerRef}
        />
      </div>
    </div>
  );
}

type ProductItemProps = {
  hit: ProductHit;
  components: AutocompleteComponents;
};

function ProductItem({ hit, components }: ProductItemProps) {
  return (
    <a href={`${hit.slug}`} className="aa-ItemWrapper">
      <div className="aa-ItemContent">
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            <components.Highlight hit={hit} attribute="title" />
          </div>
        </div>
      </div>
    </a>
  );
}
