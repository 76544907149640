import Image from "next/image";
import Link from "next/link";

const BrowseUsedCarsSection = () => {
  return (
    <div className="relative w-full h-full bg-[#2F3B55]">
      <Image
        width={1202}
        height={325}
        src="/assets/used-car.png"
        className="w-full min-h-[360px] sm:min-h-[300px] sm:max-h-[325px] object-cover opacity-60"
        alt="interested buying cars"
      />
      <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
        <div className="w-[546px] text-center space-y-8 px-10 sm:px-0">
          <h2 className="w-full text-white text-[34px] sm:text-[40px] font-bold leading-[49px]">
            Interested in buying a used car from a private seller?
          </h2>
          <div className="flex justify-center">
            <Link href="/buy">
              <button className="bg-white text-[#00b3de] border border-[#00B3DE] font-medium text-sm py-3 px-4 rounded cursor-pointer">
                Browse used cars
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrowseUsedCarsSection;
