import { useMemo, useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import Carousel from "react-multi-carousel";
import CircleLeftIcon from "@/components/Icon/CircleLeftIcon";
import CircleRightIcon from "@/components/Icon/CircleRightIcon";
import UniversalHTag from "@/libs/UniversalHTag";
import { parseTitle } from "@/libs/utils";

type Props = {
  data: any;
};
export default function TestimonialSection(props: Props) {
  const { data } = props;
  const ref = useRef(null);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 925 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 925, min: 0 },
      items: 1,
    },
  };

  const goToPrevious = (e: any) => {
    e.preventDefault();
    // @ts-ignore
    ref.current?.previous();
  };

  const goToNext = (e: any) => {
    e.preventDefault();
    // @ts-ignore
    ref.current?.next();
  };

  const testimonials = useMemo(() => data?.Testimonials?.data ?? [], []);

  return (
    <div className="w-full py-[50px] sm:py-[61px]">
      <UniversalHTag
        tag={data.TitleStyle}
        classname={`w-full text-center ${parseTitle(data.TitleStyle)}`}
        content={data.Title}
      />
      {/* <h2 className="hidden sm:block text-[40px] font-semibold text-center">
        Why people are loving PrivateAuto
      </h2>
      <h2 className="sm:hidden text-3xl font-semibold text-center">
        Why people are <br />
        loving PrivateAuto
      </h2> */}
      <div className="relative w-[90%] max-w-[350px] vs:max-w-[500px] km:max-w-none td:max-w-[1100px] mx-auto px-6 vs:px-[50px] mt-6">
        <Carousel
          ref={ref}
          additionalTransfrom={15}
          autoPlay={false}
          swipeable={true}
          draggable={true}
          showDots={false}
          arrows={false}
          responsive={responsive}
          ssr={true}
          infinite={true}
          keyBoardControl={true}
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          partialVisible={true}
          dotListClass="custom-dot-list-style"
        >
          {testimonials.map((testimonial: any) => (
            <div key={testimonial.id} className="h-full flex items-center">
              <div className="lg:w-[472px] border border-[#C9C9C9] rounded-lg space-y-2 px-3 vs:px-[29px] py-3 vs:py-[34px] mr-5">
                <div className="flex items-center space-x-2">
                  <p className="text-xs vs:text-base font-bold text-[#000] vs:pt-1">
                    {testimonial.attributes.Author}
                  </p>
                  <div className="flex space-x-0.5">
                    <Image
                      width={16}
                      height={15}
                      src="/assets/star.svg"
                      className="w-[12px] vs:w-[16px]"
                      alt="star"
                    />
                    <Image
                      width={16}
                      height={15}
                      src="/assets/star.svg"
                      className="w-[12px] vs:w-[16px]"
                      alt="star"
                    />
                    <Image
                      width={16}
                      height={15}
                      src="/assets/star.svg"
                      className="w-[12px] vs:w-[16px]"
                      alt="star"
                    />
                    <Image
                      width={16}
                      height={15}
                      src="/assets/star.svg"
                      className="w-[12px] vs:w-[16px]"
                      alt="star"
                    />
                    <Image
                      width={16}
                      height={15}
                      src="/assets/star.svg"
                      className="w-[12px] vs:w-[16px]"
                      alt="star"
                    />
                  </div>
                </div>
                <p className="sm:h-[96px] text-xs vs:text-base text-[#000] leading-[20px]">
                  {testimonial.attributes.Testimonial}
                </p>
                <Link
                  href={testimonial.attributes.VehicleLink}
                  className="text-xs vs:text-sm font-bold text-[#00B3DE]"
                >
                  {testimonial.attributes.Vehicle}
                </Link>
              </div>
            </div>
          ))}
        </Carousel>
        <div className="absolute left-2.5 lg:left-0 top-0 w-auto h-full flex items-center">
          <CircleLeftIcon
            color="#45577D"
            className="cursor-pointer w-[20px] vs:w-auto"
            onClick={goToPrevious}
          />
        </div>
        <div className="absolute right-0 top-0 w-auto h-full flex items-center">
          <CircleRightIcon
            color="#2F3B55"
            className="cursor-pointer w-[20px] vs:w-auto"
            onClick={goToNext}
          />
        </div>
      </div>
    </div>
  );
}
