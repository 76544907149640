import { useState, useEffect, ChangeEvent, useCallback } from "react";
import { useSearchValue } from "@/contexts/contextSearch";
import CloseIcon from "@/components/Icon/CloseIcon";
import debounce from "lodash/debounce";

type Props = {
  isFilterDisable: boolean;
};

type InputChangeEvent = ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement | undefined
>;

const Search = ({ isFilterDisable }: Props) => {
  const [search, setSearch] = useState<string>("");
  const { setSearchKey, setCurrent, searchKey } = useSearchValue();

  useEffect(() => {
    setSearch(searchKey);
  }, [searchKey]);

  const debounceSearch = useCallback(
    debounce((value: string) => {
      setSearchKey(value);
    }, 1000),
    [setSearchKey]
  );

  const onSearchChanged = useCallback((event: InputChangeEvent) => {
    event.persist();
    setSearch(event.currentTarget?.value);
    debounceSearch(event.currentTarget?.value);
  }, []);

  return (
    <div className="relative w-full">
      <input
        className="bg-[url('/assets/search.svg')] bg-no-repeat bg-[center_left_0.5rem] bg-[#f5f5f5] rounded-full w-full py-2 px-9 text-[#2e3b54] leading-tight border-none focus:bg-white outline-none focus:border focus:border-solid focus:border-[#17a2b8] transition-all"
        id="search"
        type="text"
        placeholder="Search..."
        value={search}
        onChange={onSearchChanged}
        disabled={isFilterDisable}
      />
      {search != "" && (
        <CloseIcon
          fill="#212529"
          className="absolute top-2.5 right-4 w-[13px] cursor-pointer"
          onClick={() => {
            setCurrent(0);
            setSearch("");
            setSearchKey("");
          }}
        />
      )}
    </div>
  );
};

export default Search;
