import Head from "next/head";
import {
  constructUrl,
  checkSellDetailPage,
  cleanupCanonicalUrl,
} from "@/libs/utils";
import { buildId } from "@/libs/clientConfig";

type Props = {
  content: any;
};

const MetaHeader = ({ content }: Props) => {
  const baseUrl = "https://privateauto.com";

  const abbr = (Description: string) => {
    const max = Math.min(Description.length, 200);
    let pos1 = Description.lastIndexOf(".", max);
    pos1 = pos1 == -1 ? max : pos1;
    const pos2 = Description.lastIndexOf(" ", pos1);
    return Description.slice(0, pos2 == -1 ? pos1 : pos2);
  };
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <base href="/" />
        {checkSellDetailPage() ? (
          <title>Vehicle Lookup | PrivateAuto</title>
        ) : (
          <title>{`${content?.PageTitle || "PrivateAuto"}`}</title>
        )}

        <link rel="icon" type="image/x-icon" href="favicon.ico" />
        <link rel="manifest" href="manifest.webmanifest" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          key="canonical"
          rel="canonical"
          href={cleanupCanonicalUrl(constructUrl(baseUrl, content?.slug))}
        />

        <meta
          key="buildmark"
          name="buildmark"
          content={JSON.stringify({ dt: new Date(), buildId })}
        ></meta>

        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <meta key="theme-color" name="theme-color" content="#1976d2" />
        <meta
          name="facebook-domain-verification"
          content="hgou7oprqmpme9utlc7oixtkaov3yl"
        />
        <meta
          name="p:domain_verify"
          content="dec5347638a7faf766214d6ced69be3a"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="translucent"
        />

        {checkSellDetailPage() ? (
          <meta
            key="description"
            name="description"
            content="Enter your VIN or License Plate to begin listing your car on PrivateAuto."
          />
        ) : (
          content?.MetaDescription && (
            <meta
              key="description"
              name="description"
              content={`${content.MetaDescription} | PrivateAuto`}
            />
          )
        )}

        <meta key="og:type" property="og:type" content="article" />
        {checkSellDetailPage() ? (
          <meta
            property="og:description"
            key="og:description"
            content="Enter your VIN or License Plate to begin listing your car on PrivateAuto."
          />
        ) : (
          content?.MetaDescription && (
            <meta
              property="og:description"
              key="og:description"
              content={`${abbr(content.MetaDescription)}`}
            />
          )
        )}
        {content?.Image?.data && (
          <meta
            property="og:image"
            key="og:image"
            content={`${content.Image.data.attributes.url}`}
          />
        )}
        {content.Image && content.Image.data && (
          <meta
            key="og:image:width"
            property="og:image:width"
            content={`${content.Image.data.attributes.width}`}
          />
        )}
        {content.Image && content.Image.data && (
          <meta
            key="og:image:height"
            property="og:image:height"
            content={`${content.Image.data.attributes.height}`}
          />
        )}
        {checkSellDetailPage() ? (
          <meta
            key="og:title"
            property="og:title"
            content="Vehicle Lookup | PrivateAuto"
          />
        ) : (
          <meta
            key="og:title"
            property="og:title"
            content={`${content?.PageTitle} | PrivateAuto`}
          />
        )}
        <meta
          key="og:url"
          property="og:url"
          content={constructUrl(baseUrl, content?.slug)}
        />
        <meta key="og:type" property="og:type" content="website" />
        <meta
          key="og:site_name"
          name="og:site_name"
          property="og:site_name"
          content="PrivateAuto"
        />

        {content?.publishedAt && (
          <meta
            key="article:published_time"
            property="article:published_time"
            content={`${content.publishedAt}`}
          />
        )}
        {content?.updatedAt && (
          <meta
            key="article:modified_time"
            property="article:modified_time"
            content={`${content.updatedAt}`}
          />
        )}

        {content?.MetaDescription && (
          <meta
            key="twitter:description"
            property="twitter:description"
            content={`${abbr(content.MetaDescription)}`}
          />
        )}

        <meta
          key="twitter:card"
          property="twitter:card"
          content="summary_large_image"
        />
        <meta
          key="twitter:creator"
          name="twitter:creator"
          content="_privateauto"
        />
        <meta key="twitter:site" name="twitter:site" content="_privateauto" />
        <meta
          key="twitter:url"
          name="twitter:url"
          content={constructUrl(baseUrl, content?.slug)}
        />
        {checkSellDetailPage() ? (
          <meta
            key="twitter:title"
            name="twitter:title"
            content="Vehicle Lookup | PrivateAuto"
          />
        ) : (
          <meta
            key="twitter:title"
            name="twitter:title"
            content={`${content?.PageTitle} | PrivateAuto`}
          />
        )}
        {content?.Image?.data && (
          <meta
            key="twitter:image"
            property="twitter:image"
            content={`${content.Image.data.attributes.url}`}
          />
        )}
        <meta
          key="twitter:image:alt"
          name="twitter:image:alt"
          content={`${content?.PageTitle} | PrivateAuto`}
        />

        <meta
          httpEquiv="origin-trial"
          content="A751Xsk4ZW3DVQ8WZng2Dk5s3YzAyqncTzgv+VaE6wavgTY0QHkDvUTET1o7HanhuJO8lgv1Vvc88Ij78W1FIAAAAAB7eyJvcmlnaW4iOiJodHRwczovL3d3dy5nb29nbGV0YWdtYW5hZ2VyLmNvbTo0NDMiLCJmZWF0dXJlIjoiUHJpdmFjeVNhbmRib3hBZHNBUElzIiwiZXhwaXJ5IjoxNjgwNjUyNzk5LCJpc1RoaXJkUGFydHkiOnRydWV9"
        />
        <script
          key="application/ld+json"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{
        
        "@context": "https://schema.org",
        "@type": "Article",
        "publisher": {
            "@type": "Organization",
            "name": "PrivateAuto",
            "url": "https://privateauto.com",
            "logo": {
              "@type": "ImageObject",
              "url": "${
                content?.Image?.data ? content.Image.data.attributes.url : ""
              }"
          }
        },
        "headline": "${content?.PageTitle}",
        "url": "${constructUrl(baseUrl, content?.slug)}",
        "datePublished": "${content?.publishedAt}",
        "dateModified": "${content?.updatedAt}",
        "image": {
          "@type": "ImageObject",
          "url": "${
            content?.Image?.data ? content.Image.data.attributes.url : ""
          }",
          "width": "${
            content?.Image?.data ? content.Image.data.attributes.width : 0
          }",
          "height": "${
            content?.Image?.data ? content.Image.data.attributes.height : 0
          }"
        },
        "keywords": "Car Title",
        "description": "${
          content?.MetaDescription ? abbr(content.MetaDescription) : ""
        }",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "${baseUrl}"
        }
      }`,
          }}
        ></script>
      </Head>
    </>
  );
};

export default MetaHeader;
