import Link from "next/link";
import Image from "next/image";
import {
  parseTitle,
  checkHomePage,
  parseContentBlockContent,
  checkFinancingCarsPage,
  checkCarInsurancePage,
  checkTranportCarsPage,
  parseTextColor,
  checkInsurancePage,
} from "@/libs/utils";

type Props = {
  color?: string;
  data: any;
  isBelongedToColumn?: Boolean;
};

const IconBar = ({ color = "", data, isBelongedToColumn = false }: Props) => {
  return isBelongedToColumn ? (
    <div></div>
  ) : (
    <div className="w-full lg:max-w-[1140px] px-0 xs:px-[8%] lg:px-0 mx-auto text-[#212529] space-y-16">
      <div className="w-full space-y-6">
        {data.Title != null && (
          <div
            className={`text-center ${parseTitle(
              data.TitleStyle,
              data.Title
            )} pt-10`}
          >
            {data.Title}
          </div>
        )}
        <div
          className={`w-full mx-auto ${
            checkHomePage() ? "block vs:flex" : "flex"
          } items-center justify-center ${checkHomePage() && "flex-wrap"}`}
        >
          {data.Links.map((item: any) => {
            if (item.URL != null) {
              return (
                <Link
                  className={`flex justify-center items-end ${
                    checkHomePage() && "py-8 sm:py-10"
                  } px-4 sm:px-8 ${
                    (checkFinancingCarsPage() ||
                      checkCarInsurancePage() ||
                      checkTranportCarsPage() ||
                      checkInsurancePage()) &&
                    "sm:h-[220px]"
                  }`}
                  href={item.URL || ""}
                  key={item.id}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item?.Image?.data?.attributes && (
                    <Image
                      width={item.Image.data.attributes.width}
                      height={item.Image.data.attributes.height}
                      src={item.Image.data.attributes.url}
                      alt={item.Image.data.attributes.alternativeText || ""}
                    />
                  )}
                </Link>
              );
            } else {
              return (
                <div
                  key={item.id}
                  className={`flex ${
                    checkHomePage() &&
                    "justify-center py-8 sm:py-10 px-4 sm:px-8"
                  } items-end ${
                    (checkFinancingCarsPage() ||
                      checkCarInsurancePage() ||
                      checkTranportCarsPage() ||
                      checkInsurancePage()) &&
                    "sm:h-[220px]"
                  }`}
                >
                  {item?.Image?.data?.attributes && (
                    <Image
                      width={item.Image.data.attributes.width}
                      height={item.Image.data.attributes.height}
                      src={item.Image.data.attributes.url}
                      alt={item.Image.data.attributes.alternativeText || ""}
                    />
                  )}
                </div>
              );
            }
          })}
        </div>
        {data.Content != null && (
          <div
            className={`max-w-[690px] text-xl text-center ${
              (checkFinancingCarsPage() ||
                checkCarInsurancePage() ||
                checkTranportCarsPage() ||
                checkInsurancePage()) &&
              "xs:px-4 pb-6 xs:pb-0 pt-6"
            } ${
              color != "" &&
              (checkCarInsurancePage()
                ? color == "Dark Blue"
                  ? "text-white"
                  : parseTextColor(color)
                : "")
            } mx-auto`}
            dangerouslySetInnerHTML={{
              __html: parseContentBlockContent(data.Content),
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default IconBar;
