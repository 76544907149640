import Image from "next/image";
import { parseWidth, parseTitle } from "@/libs/utils";
import UniversalHTag from "@/libs/UniversalHTag";

type Props = {
  data: any;
};

const BulletedList = ({ data }: Props) => {
  let url = "";
  let alt = "";
  let width = 0;
  let height = 0;
  return (
    <div className={`${parseWidth(data.Width)} space-y-6 my-6`}>
      <UniversalHTag
        tag={data.TitleStyle}
        classname={`w-full text-center ${parseTitle(data.TitleStyle)}`}
        content={data.Title}
      />
      <div className="w-full sm:grid grid-rows-2 grid-flow-col">
        {data.Items.map((item: any, index: number) => {
          if (item?.Image?.data?.attributes != null) {
            url = item.Image?.data.attributes.url;
            alt = item.Image?.data.attributesalternativeText || "";
            width = item.Image?.data.attributes.width;
            height = item.Image?.data.attributes.height;
          }
          return (
            <div className="w-full flex items-start space-x-4 mb-6" key={index}>
              {item?.Image?.data != null && (
                <Image
                  className={`w-[${width}]px h-[${height}px] mt-[2px]`}
                  width={width}
                  height={height}
                  src={url}
                  alt={alt}
                />
              )}
              <div className="text-lg md:text-2xl text-[#4f4f4f] font-normal">
                {item.Text}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BulletedList;
