import GoogleReviewsScript from "./scripts/GoogleReviews";

type Props = {
  data: any;
};

const Scripts = ({ data }: Props) => {
  return (
    <div>
      {data.Script === "Google Reviews" && (
        <>
          <GoogleReviewsScript />
        </>
      )}
    </div>
  );
};

export default Scripts;
