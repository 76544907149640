import Image from "next/image";
import {
  checkBlogPage,
  checkInsurancePage,
  checkNewsAnnouncingPage,
  checkTranportCarsPage,
} from "@/libs/utils";

type Props = {
  data: any;
};

const InlineImage = ({ data }: Props) => {
  let img = data?.Image?.data?.attributes;
  if (checkBlogPage()) {
    img = data?.Image?.data?.attributes?.formats?.large;
  }

  const width = data?.Width || img?.width;
  const height = (data?.Width || img?.width) * (img?.height / img?.width);

  const image = (
    <Image
      className={`${
        data?.Width && data?.Height
          ? `w-[${data?.Width}px] h-[${data?.Height}px]`
          : checkTranportCarsPage()
          ? "w-[900px]"
          : checkBlogPage()
          ? "w-[720px]"
          : checkInsurancePage()
          ? ""
          : `w-[${width}px] h-[${height}px]`
      } ${!checkNewsAnnouncingPage() && !checkBlogPage() && "mt-2"} `}
      src={img?.url}
      sizes={`(min-width: ${data?.Width * 0.06 + data?.Width}px) ${
        data?.Width
      }px, calc(94vw + 18px)`}
      priority
      width={data?.Width || img?.width}
      height={data?.Height || img?.height}
      alt={data?.Title || img?.name || ""}
    />
  );

  return (
    <div
      style={{
        height: `auto`,
      }}
      className={`relative flex justify-center w-100 ${
        data?.Image?.data?.attributes?.alternativeText ===
        "PrivateAuto vs the Competition"
          ? ""
          : checkNewsAnnouncingPage()
          ? "pb-6"
          : checkBlogPage()
          ? "my-0"
          : "my-6"
      }`}
    >
      {img && data.URL ? <a href={data.URL}>{image}</a> : image}
    </div>
  );
};

export default InlineImage;
