const HowItWorks = () => {
  return (
    <div className="w-full lg:max-w-[1140px] px-[8%] lg:px-0 mx-auto mt-12 mb-6 space-y-16">
      <div className="w-full space-y-8">
        <div className="flex justify-center">
          <h1 className="text-center text-[40px] font-bold text-[#212529]">
            How it Works
          </h1>
        </div>
        <div className="w-full flex justify-center space-x-6 md:space-x-24">
          <div className="justify-items-center space-y-2">
            <div className="flex justify-center">
              <div className="w-[68px] h-[68px] flex items-center justify-center bg-[#45577d] text-white text-4xl rounded-full ">
                1
              </div>
            </div>
            <div className="text-xl text-center text-[#212529] font-bold">
              Get listed.
            </div>
          </div>
          <div className="justify-items-center space-y-2">
            <div className="flex justify-center">
              <div className="w-[68px] h-[68px] flex items-center justify-center bg-[#45577d] text-white text-4xl rounded-full ">
                2
              </div>
            </div>
            <div className="text-xl text-center text-[#212529] font-bold">
              Get found.
            </div>
          </div>
          <div className="justify-items-center space-y-2">
            <div className="flex justify-center">
              <div className="w-[68px] h-[68px] flex items-center justify-center bg-[#45577d] text-white text-4xl rounded-full ">
                3
              </div>
            </div>
            <div className="text-xl text-center text-[#212529] font-bold">
              Get paid.
            </div>
          </div>
        </div>
        <div className="w-full md:w-4/5 mx-auto text-center text-lg md:text-xl font-normal">
          All within a single easy app. No giving out personal details or
          contact information. It&apos;s so streamlined and intuitive,
          you&apos;ll want to sell another.
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
