import { useState, useEffect, useRef } from "react";
import Image from "next/image";
import { AnimatePresence, motion } from "framer-motion";
import VehicleTypeModal from "@/components/buy/modals/VehicleTypeModal";
import MakeModelModal from "@/components/buy/modals/MakeModelModal";
import FilterModal from "@/components/buy/modals/FilterModal";
import LocationModal from "@/components/buy/modals/LocationModal";
import SortModal from "@/components/buy/modals/SortModal";
import Search from "@/components/buy/Search";
import MapIcon from "@/components/Icon/MapIcon";
import ClearIcon from "@/components/Icon/ClearIcon";
import { useSearchValue } from "@/contexts/contextSearch";
import {
  MIN_PRICE,
  MAX_PRICE,
  MIN_YEAR,
  MAX_YEAR,
  MIN_MILES,
  MAX_MILES,
} from "@/libs/constants";
import { googleAutocompleteApiKey } from "@/libs/clientConfig";

type Props = {
  makeData: Array<string>;
  moreFilterData: any;
  clearAll: Function;
  isFilterDisable: boolean;
  currentPage: number;
  exactCnt: number;
  rows: number;
  checkIfFiltered: Function;
  pageSize: number;
};

const Filter = ({
  makeData,
  moreFilterData,
  clearAll,
  isFilterDisable,
  currentPage,
  exactCnt,
  rows,
  checkIfFiltered,
  pageSize,
}: Props) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const vehicleBtnRef = useRef<HTMLButtonElement>(null);
  const {
    vehicleType,
    make,
    models,
    sort,
    setSort,
    minPrice,
    maxPrice,
    minYear,
    maxYear,
    minMiles,
    maxMiles,
    isVerified,
    bodyType,
    moreFiltersArr,
    location,
    radius,
    clearLocation,
    clearMakeModel,
    clearFilters,
  } = useSearchValue();
  const [isVehicleModal, setIsVehicleModal] = useState<Boolean>(false);
  const [isMakeModelModal, setIsMakeModelModal] = useState<Boolean>(false);
  const [isFilterModal, setIsFilterModal] = useState<Boolean>(false);
  const [isLocationModal, setIsLocationModal] = useState<Boolean>(false);
  const [isSortModal, setIsSortModal] = useState<Boolean>(false);
  const [filtersCnt, setFiltersCnt] = useState<number>(0);
  const [filterFieldCnt, setFilterFieldCnt] = useState<number>(0);

  const initModal = () => {
    setIsVehicleModal(false);
    setIsMakeModelModal(false);
    setIsLocationModal(false);
    setIsSortModal(false);
  };

  const vehicleModal = () => {
    initModal();
    setIsVehicleModal(!isVehicleModal);
  };

  const makeModelModal = () => {
    initModal();
    setIsMakeModelModal(!isMakeModelModal);
  };

  const filtersModal = () => {
    initModal();
    setIsFilterModal(!isFilterModal);
  };

  const locationModal = () => {
    initModal();
    setIsLocationModal(!isLocationModal);
  };

  const sortModal = () => {
    initModal();
    setIsSortModal(!isSortModal);
  };

  const setScrollHidden = () => {
    if (document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  };

  useEffect(() => {
    let filtersCount = 0;
    if (minPrice != MIN_PRICE || maxPrice != MAX_PRICE) {
      filtersCount++;
    }
    if (minYear != MIN_YEAR || maxYear != MAX_YEAR) {
      filtersCount++;
    }
    if (minMiles != MIN_MILES || maxMiles != MAX_MILES) {
      filtersCount++;
    }
    if (bodyType.length > 0) {
      filtersCount++;
    }
    if (moreFiltersArr.exteriorColor.length > 0) {
      filtersCount++;
    }
    if (moreFiltersArr.interiorColor.length > 0) {
      filtersCount++;
    }
    if (moreFiltersArr.fuelType.length > 0) {
      filtersCount++;
    }
    if (moreFiltersArr.transmission.length > 0) {
      filtersCount++;
    }
    if (moreFiltersArr.driveType.length > 0) {
      filtersCount++;
    }
    if (moreFiltersArr.driveType.length > 0) {
      filtersCount++;
    }
    if (moreFiltersArr.cylinders.length > 0) {
      filtersCount++;
    }
    if (isVerified) {
      filtersCount++;
    }
    setFiltersCnt(filtersCount);
  }, [
    minPrice,
    maxPrice,
    minYear,
    maxYear,
    minMiles,
    maxMiles,
    isVerified,
    bodyType,
    moreFiltersArr,
  ]);

  useEffect(() => {
    let count = 0;
    if (location != "") {
      count++;
    }
    if (make != "") {
      count++;
    }
    if (filtersCnt != 0) {
      count++;
    }
    setFilterFieldCnt(count);
  }, [
    location,
    radius,
    make,
    models,
    minPrice,
    maxPrice,
    minYear,
    maxYear,
    minMiles,
    maxMiles,
    isVerified,
    bodyType,
    moreFiltersArr,
    filtersCnt,
  ]);

  return (
    <div className="w-full">
      <div className="w-full lg:max-w-[1140px] px-2 vs:px-[8%] lg:px-0 mx-auto bg-white">
        <div className="relative z-20">
          <button
            className="flex items-center space-x-1 xs:space-x-2 vs:space-x-4 py-4"
            onClick={() => vehicleModal()}
            disabled={isFilterDisable}
            ref={vehicleBtnRef}
          >
            <p className="text-lg text-[#333] font-medium">{vehicleType}</p>
            <Image
              width={10}
              height={6}
              src="/assets/expand_icon.svg"
              alt="null"
            />
          </button>
          {isVehicleModal && (
            <VehicleTypeModal
              isVehicleModal={isVehicleModal}
              setIsVehicleModal={setIsVehicleModal}
              btnRef={vehicleBtnRef}
            />
          )}
        </div>
        <Search isFilterDisable={isFilterDisable} />
        <div className="flex flex-wrap items-center pt-4">
          <div className="py-3 mr-2 xs:mr-4">
            <button
              className={`${
                location == ""
                  ? "bg-white text-[#8b8b8b] border-[#a7a7a7]"
                  : "bg-[#e5faff] text-[#00b3de] border-[#00b3de]"
              }  border text-xs xs:text-sm font-medium px-2 py-2 rounded`}
              disabled={isFilterDisable}
            >
              {location == "" ? (
                <div
                  className="w-full flex items-center space-x-1"
                  onClick={() => {
                    locationModal();
                    setScrollHidden();
                  }}
                >
                  <MapIcon />
                  <p>All locations</p>
                </div>
              ) : (
                <div className="flex items-center space-x-1">
                  <div
                    className="flex items-center space-x-1"
                    onClick={() => {
                      locationModal();
                      setScrollHidden();
                    }}
                  >
                    <p>{location}</p>
                    <div className="w-[4px] h-[4px] bg-[#00b3de] rounded-full"></div>
                    <p>{radius}mi</p>
                  </div>
                  <ClearIcon onClick={() => clearLocation()} />
                </div>
              )}
            </button>
            {isLocationModal && (
              <LocationModal
                setIsLocationModal={setIsLocationModal}
                isLocationModal={isLocationModal}
                googleAutocompleteApiKey={googleAutocompleteApiKey}
              />
            )}
          </div>
          <div className="py-3 mr-2 xs:mr-4">
            <button
              className={`${make == "" ? "bg-white" : "bg-[#e5faff]"} border ${
                make == "" ? "border-[#a7a7a7]" : "border-[#00b3de]"
              } flex items-center text-[#8b8b8b] text-xs xs:text-sm font-medium px-2 space-x-4 py-2 rounded`}
              disabled={isFilterDisable}
            >
              {make == "" ? (
                <div
                  onClick={() => {
                    makeModelModal();
                    setScrollHidden();
                  }}
                >
                  Make and Model
                </div>
              ) : (
                <div className="flex items-center space-x-1 text-[#00b3de]">
                  <div
                    className="flex items-center space-x-1"
                    onClick={() => {
                      makeModelModal();
                      setScrollHidden();
                    }}
                  >
                    <p>{make}</p>
                    <div className="w-[4px] h-[4px] bg-[#00b3de] rounded-full"></div>
                    {models.length != 0 && <p>{models[0]}</p>}
                  </div>
                  <ClearIcon onClick={() => clearMakeModel()} />
                </div>
              )}
            </button>
            {isMakeModelModal && (
              <MakeModelModal
                makeData={makeData}
                setIsMakeModelModal={setIsMakeModelModal}
                isMakeModelModal={isMakeModelModal}
              />
            )}
          </div>
          <div className="py-3 mr-2 xs:mr-4">
            <button
              className={`${
                filtersCnt == 0
                  ? "bg-white text-[#8b8b8b] border-[#a7a7a7]"
                  : "bg-[#e5faff] text-[#00b3de] border-[#00b3de]"
              }  border  text-xs xs:text-sm font-medium px-2 space-x-4 py-2 rounded`}
              disabled={isFilterDisable}
            >
              <div className="flex items-center space-x-2">
                <p
                  onClick={() => {
                    filtersModal();
                    setScrollHidden();
                  }}
                >
                  Filters{filtersCnt != 0 && "(" + filtersCnt + ")"}
                </p>
                {filtersCnt != 0 && (
                  <ClearIcon onClick={() => clearFilters()} />
                )}
              </div>
            </button>
            <AnimatePresence>
              {isFilterModal && (
                <motion.div
                  initial={{ y: -30 }}
                  animate={{ y: 0 }}
                  exit={{ y: -30, transition: { duration: 0 } }}
                  transition={{ duration: 0.2 }}
                  className="fixed top-0 left-0 w-full h-screen z-[10000000] inset-0"
                >
                  <FilterModal
                    moreFilterData={moreFilterData}
                    isFilterModal={isFilterModal}
                    setIsFilterModal={setIsFilterModal}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            {isFilterModal && (
              <div className="fixed top-0 left-0 w-full h-screen justify-center items-center overflow-x-hidden overflow-y-hidden inset-0 z-40 outline-none focus:outline-none bg-black bg-opacity-60 "></div>
            )}
          </div>
          {filterFieldCnt > 0 && (
            <button
              className="flex items-center mr-2 xs:mr-4 py-3"
              onClick={() => clearAll()}
              disabled={isFilterDisable}
            >
              <span className="text-sm">Clear filters</span>
            </button>
          )}
        </div>
      </div>
      <div className="w-full bg-[#f8f8f8]">
        <div className="w-full lg:max-w-[1140px] px-4 vs:px-[8%] lg:px-0 mx-auto">
          {checkIfFiltered() && currentPage * pageSize < exactCnt ? (
            exactCnt == 1 ? (
              <div className="w-full text-lg font-semibold py-3">
                Showing only 1 vehicle
              </div>
            ) : (
              <div className="w-full text-lg font-semibold py-3">
                Showing {currentPage * pageSize + 1}-
                {(currentPage + 1) * pageSize < exactCnt
                  ? (currentPage + 1) * pageSize
                  : exactCnt}
                {` of ${exactCnt} vehicles`}
              </div>
            )
          ) : (
            ""
          )}
          <div className="w-full flex justify-end">
            <div className="relative">
              {exactCnt > 0 && (
                <button
                  className={`flex items-center text-sm font-medium space-x-1 vs:space-x-4 ${
                    checkIfFiltered() ? "pt-3" : "pt-8"
                  } pb-2`}
                  onClick={() => sortModal()}
                  disabled={isFilterDisable}
                  ref={btnRef}
                >
                  <span className="hidden md:block text-slate-400">
                    Sort by:
                  </span>
                  <span className="text-[#2c564a]">
                    {sort == "Newest" ? "Newest Inventory" : sort}
                  </span>
                  <Image
                    width={10}
                    height={6}
                    src="/assets/expand_more.svg"
                    alt="null"
                  />
                </button>
              )}
              {isSortModal && (
                <SortModal
                  isSortModal={isSortModal}
                  setIsSortModal={setIsSortModal}
                  setSort={setSort}
                  btnRef={btnRef}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
